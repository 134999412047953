import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class AppRouteReuseStrategy implements RouteReuseStrategy {

  // These routes will always be cached and restored
  private readonly reusableRoutes = [
    'search',
  ];

  private readonly storedRoutes = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig && route.routeConfig.path && this.reusableRoutes.indexOf(route.routeConfig.path) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.routeConfig && route.routeConfig.path) {
      this.storedRoutes.set(route.routeConfig.path, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig && route.routeConfig.path && this.reusableRoutes.indexOf(route.routeConfig.path) !== -1) {
      return this.storedRoutes.has(route.routeConfig.path);
    } else {
      return false;
    }
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routeHandle = route.routeConfig && route.routeConfig.path ? this.storedRoutes.get(route.routeConfig.path) : null;
    return routeHandle ? routeHandle : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

}