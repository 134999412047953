import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

import { UserSessionService } from './services/user-session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userSessionService: UserSessionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't process the intercept until the session service has set the session key
    return this.userSessionService.sessionKey$.pipe(
      first(), // We only want the next session key -- if the session key resets, we don't want to retrigger all the network requests!
      mergeMap((sessionKey: string) => {
        // Clone the request and set the new header in one step.
        const authReq = req.clone({ setHeaders: { Authorization: 'AutoLink UUID="' + sessionKey + '"' } });

        // send cloned request with header to the next handler.
        return next.handle(authReq);
      }),
    );
  }
}