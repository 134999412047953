<div class="car-sale-registration card d-flex">
  <div class="d-flex flex-column card-body py-0">

  <ng-container *ngTemplateOutlet="saleinfo"></ng-container>

  <form
    [formGroup]="contactForm"
    class="validated-form d-flex flex-grow-1 justify-content-center my-2"
    style="padding-bottom: .75rem"
    [ngClass]="{'align-items-center': status === DONE}"
    (submit)="submitContactInformation()"
  >

    <div *ngIf="status === INIT">

      <p class="mt-3 mb-1 font-weight-bold">Your Registration Information</p>
      <p class="text-muted small mb-3">Don't worry &mdash; the information you provide here will not be sold to third-parties.</p>


      <div class="form-row">
        <div class="col form-group">
          <label for="firstNameInput">First Name</label>
          <input id="firstNameInput" formControlName="firstName" class="form-control" required #firstNameInput>
        </div>
        <div class="col form-group">
          <label for="lastNameInput">Last Name</label>
          <input id="lastNameInput" formControlName="lastName" class="form-control" required>
        </div>
      </div>
      <div class="form-row">
        <div class="col form-group">
          <label for="emailAddressInput">Email Address</label>
          <input id="emailAddressInput" formControlName="emailAddress" class="form-control" type="email" required>
        </div>
        <div class="col form-group">
          <label for="phoneInput">Phone Number</label>
          <input id="phoneInput" formControlName="phoneNumber" class="form-control" type="tel"
            (keyup)="formatPhoneInContactForm()">
        </div>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <button type="submit" class="btn btn-primary px-5" [disabled]="contactForm.invalid">Register for the sale!</button>
      </div>
    </div>

    <div *ngIf="status === DONE">
      <div class="d-flex flex-column align-items-center mb-5">
        <svg fill="#42A948"  height="72" viewBox="0 0 24 24" width="72" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM10.75 13.4393L15.2197 8.96967C15.5126 8.67678 15.9874 8.67678 16.2803 8.96967C16.5466 9.23594 16.5708 9.6526 16.3529 9.94621L16.2803 10.0303L11.2803 15.0303C11.0141 15.2966 10.5974 15.3208 10.3038 15.1029L10.2197 15.0303L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697C7.98594 11.2034 8.4026 11.1792 8.69621 11.3971L8.78033 11.4697L10.75 13.4393L15.2197 8.96967L10.75 13.4393Z" /></svg>
        <h4 class="text-center mt-4">You're all set &mdash; thank you!</h4>
      </div>
    </div>

  </form>
</div>

</div>

<ng-template #saleinfo>
  <div class="d-flex justify-content-center car-sale-info py-2 px-3">
    <div class="d-flex flex-column justify-content-center">
      <div class="d-flex flex-row justify-content-between align-items-center mb-2">
        <h4 class="text-muted mb-0">
          Register for our <wbr>Virtual Car Sale!
        </h4>
        <img src="/assets/img/virtual-car-sale.png" alt="" class="car-sale-img">
      </div>
      <h6>
        <ng-container>{{ configService.virtualCarSaleConfig?.description }}</ng-container>
        <ng-container><br><br>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style="white-space: nowrap;"
            *ngIf="configService.virtualCarSaleConfig?.learnMoreURL"
            [href]="configService.virtualCarSaleConfig?.learnMoreURL"
          >
            Learn More
          </a>
        </ng-container>
      </h6>
    </div>
  </div>
</ng-template>