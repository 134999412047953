<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 pl-md-0">
      <app-search-form (newSearch)="loadResults($event)"></app-search-form>
    </div>

    <div class="col">
      <div class="float-right mt-3"><app-account-btns></app-account-btns></div>
      <h1 class="mt-3">Saved Searches</h1>

      <p>Quickly return to your previously saved searches here. Click on a search to pull it up.</p>

      <div class="d-flex flex-wrap">
        <div *ngFor="let search of savedSearches" class="d-flex mr-3 mb-3">
          <div class="d-flex flex-wrap p-2 search-block" [routerLink]="['/search', search.searchData]">
            <div *ngFor="let searchKey of searchKeys(search)" class="p-2 search-parameter">
              <div class="small text-muted">{{prettyPrintKey(searchKey)}}</div>
              <div class="font-weight-bold">{{search.searchData[searchKey]}}</div>
            </div>
          </div>
          <div>
            <button class="btn btn-warning btn-sm" (click)="deleteSearch(search.savedSearchID)">X</button>
          </div>
        </div>
      </div>

      <p *ngIf="savedSearches.length === 0"><strong>No saved searches</strong></p>
    </div>
  </div>
</div>