<div class="search-result l2-search-1col">

  <div class="l2-search-col12">
    <div class="l2-search-col3">
      <app-vehicle-top-bar-search [listing]="listing" (compareView)="compareView.emit($event)"></app-vehicle-top-bar-search>
      <div *ngIf="(listing.price > 0 && getHasAFG() == true && (showAfgBadge() | async))">
        <a [routerLink]="['/loans', listing.classifiedAdID]"><app-afg-badge [vcsEnabled]="true"></app-afg-badge></a>
      </div>
      <div class="l2-search-new">
        <app-new-listing-flag [vcsEnabled]="true" *ngIf="listing.daysOnMarket <= 7"></app-new-listing-flag>
      </div>
      <div class="l2-search-image">
        <app-image-slider [vcsEnabled]="true" [imageUrl]="listing.imageUrl" (click)="viewListing($event)" (auxclick)="viewListing($event)" class="card-img-top go-to-listing"></app-image-slider>
      </div>
      
      <div class="l2-search-rating l2-search-rating-col1">
        <app-price-rating-search [rating]="listing.priceRating"></app-price-rating-search>
      </div>
    </div>
    <div class="l2-search-col9">
      <div class="content card-body d-flex flex-column justify-content-between p-3">
        <div class="descr go-to-listing mb-2" (click)="viewListing($event)" (auxclick)="viewListing($event)">
          <div class="l2-search-title">
            <h3 class="condition year make model trim card-title m-0">{{listing.year}} {{listing.make}} {{listing.model}} <span class="text-muted">{{listing.trim}}<span *ngIf="listing.isNew"> (New)</span></span></h3>
            <ng-container *ngIf="listing.daysOnMarket > 0">
              <div class="font-italic">Listed {{listing.daysOnMarket}} day<ng-container *ngIf="listing.daysOnMarket !== 1">s</ng-container> ago</div>
            </ng-container>
            <ng-container *ngIf="listing.daysOnMarket === 0">
              <div class="font-italic">Just listed <strong>today</strong>!</div>
            </ng-container>
          </div>
        </div>
        <div class="listing-info d-flex flex-row justify-content-between">
          <div class="flex-fill">
            <div class="label">Price</div>
            <div class="content">
              <ng-container [ngSwitch]="listing.price">
                <span class="price" *ngSwitchCase="0">Contact dealer for price</span>
                <span class="price" *ngSwitchDefault>{{listing.price | currency:'USD':digitsInfo:'1.0-0'}} </span>
              </ng-container>
            </div>
          </div>
          <div *ngIf="listing.price > 0" class="flex-fill">
            <div class="label" *ngIf="getHasAFG() == true">Conventional Loan</div>
            <div class="label" *ngIf="getHasAFG() != true">Estimated Monthly Payment</div>
            <div class="content">
              <div class="mt-1 mt-sm-0"><app-monthly-payment [listing]="listing"></app-monthly-payment></div>
            </div>
          </div>
          <div *ngIf="listing.price > 0 && getHasAFG() == true" class="flex-fill">
            <div class="label">{{configService.balloonLoanProgramName}} Loan</div>
            <div class="content">
              <div class="mt-1 mt-sm-0">
                <app-monthly-payment [listing]="listing" [conventionalLoan]="false"></app-monthly-payment>
              </div>
            </div>
          </div>

          <div class="flex-fill">
            <div class="label">Odometer</div>
            <div class="content">
              <span *ngIf="listing.mileage !== null">{{listing.mileage | number}} mile<ng-container *ngIf="listing.mileage !== 1">s</ng-container></span>
              <span *ngIf="listing.mileage === null">Not provided</span>
            </div>
          </div>
          <div class="flex-fill">
            <div class="label">Location</div>
            <div class="content">
              {{listing.location.city}}, {{listing.location.state}}<br>
            </div>
          </div>
          <div class="flex-fill">
            <div class="label">Distance</div>
            <div class="content">
              {{listing.location.distanceMiles | number}} mile<ng-container *ngIf="listing.location.distanceMiles !== 1">s</ng-container> away
            </div>
          </div>
        </div>
        <div class="l2-search-dealer-text" *ngIf="listing.seller.notes">
          <span><strong class="text-uppercase">Dealer's Description:</strong> {{ listing.seller.notes | slice:0:250 }} ... <a (click)="viewListing($event)" (auxclick)="viewListing($event)" class="l2-search-readmore">Read More &gt;</a></span>
        </div>
        <div class="listing-info d-flex flex-row justify-content-between">
          <div class="l2-search-dealer" *ngIf="listing.dealerTags.isVirtualCarSaleParticipant">
            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M413-182v-227l57-168q2-7 8-12t17-5h303q11 0 16.5 4.5T823-577l57 168v227q0 9.308-6.346 15.654T858-160h-22.256q-8.744 0-15.244-6.346T814-182v-45H479v45q0 9.308-6.346 15.654T457-160h-22.256q-8.744 0-15.244-6.346T413-182Zm53-257h361l-38-115H504l-38 115Zm-13 40v132-132Zm67 106q16.575 0 27.787-11.212Q559-315.425 559-332q0-16.575-11.213-27.788Q536.575-371 520-371t-27.788 11.212Q481-348.575 481-332q0 16.575 11.212 27.788Q503.425-293 520-293Zm253 0q16.575 0 27.787-11.212Q812-315.425 812-332q0-16.575-11.213-27.788Q789.575-371 773-371t-27.787 11.212Q734-348.575 734-332q0 16.575 11.213 27.788Q756.425-293 773-293ZM164-160v-21l57-57q-57 0-99-34.5T80-361v-328q0-66 52-88.5T338-800q143 0 200.5 24.5T596-689v65h-60v-77H140v269h243v272H164Zm27-162q16.575 0 27.788-11.212Q230-344.425 230-361q0-16.575-11.212-27.788Q207.575-400 191-400q-16.575 0-27.788 11.212Q152-377.575 152-361q0 16.575 11.212 27.788Q174.425-322 191-322Zm262 55h387v-132H453v132Z"/></svg>
            <span *ngIf="!listing.seller.promoMessage">{{ listing.seller.name }} is a Participating Dealer</span>
            <span *ngIf="listing.seller.promoMessage">{{ listing.seller.name }} is offering a member bonus: <b>{{ listing.seller.promoMessage }}</b></span>
          </div>
        </div>

        <div class="l2-search-btns">
          <div class="d-flex flex-row justify-content-space-between listing-details l2-search-btns-col">
            <button type="button" *ngIf="listing.freeHistoryReport" class="l2-search-btns-col-btn w-100 btn btn-dark btn-sm l2-btn-dark" (click)="carFaxService.pullCarFaxReport(listing.classifiedAdID)" ngbTooltip="A free history report may be available for this vehicle. Click to download.">Get Free {{listing.freeHistoryReport}}</button>
            <button type="button" *ngIf="!listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" class="l2-search-btns-col-btn w-100 btn btn-dark btn-sm l2-btn-dark" (click)="requestVehicleHistory()" (auxclick)="requestVehicleHistory()" ngbTooltip="Check if a free vehicle history report is available for this vehicle.">Request {{configService.vehicleHistoryRequestConfig.reportName}}</button>
            <button type="button" *ngIf="!listing.freeHistoryReport && !configService.vehicleHistoryRequestConfig" class="l2-search-btns-col-btn w-100 btn btn-outline-primary btn-sm l2-btn-outline" (click)="checkAvailability()">Check Availability</button>
            <button type="button" *ngIf="listing.freeHistoryReport || !listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" class="l2-search-btns-col-btn w-100 btn btn-outline-primary btn-sm l2-btn-outline" (click)="checkAvailability()">Check Availability</button>
          </div>

          <div class="d-flex justify-content-space-between l2-search-btns-col">
            <div ngbDropdown class="l2-search-btns-col-btn" *ngIf="!configService.virtualCarSaleConfig?.id">
              <button ngbDropdownToggle class="btn btn-outline-dark w-100 find-better-price-btn l2-btn-prim" [ngbPopover]="fbpContent" triggers="mouseenter:mouseleave" popoverTitle="How It Works" [openDelay]="100" placement="right-bottom l2-btn-prim" container="body" [autoClose]="false">Find Better Price </button>
              <div ngbDropdownMenu>
                <button (click)="doFindBetter('state')" ngbDropdownItem>Search within entire state of {{listing.location.state}}</button>
                <button (click)="doFindBetter('regional')" ngbDropdownItem>Search within {{FIND_BETTER_PRICE_REGION}} miles of {{userSessionService.zipCode}}</button>
                <button (click)="doFindBetter('nationwide')" ngbDropdownItem>Search nationwide</button>
              </div>
            </div>
            <button type="button" class="l2-search-btns-col-btn w-100 btn btn-primary go-to-listing ml-1 l2-btn-darkoutline" type="submit" (click)="viewListing($event)" (auxclick)="viewListing($event)">Details & Pictures</button>
          </div>
        </div>
      </div>
    
    </div> <!-- close col 9 -->
  </div> <!-- close of div image and contents -->
  <!-- small screen -->
  <div class="l2-search-btns-sm card-footer">
    <div *ngIf="listing.seller.notes" class="l2-search-dealer-text">
      <span><strong class="text-uppercase">DETAILS:</strong> {{ listing.seller.notes | slice:0:250 }} ... <a (click)="viewListing($event)" (auxclick)="viewListing($event)" class="l2-search-readmore">Read More &gt;</a></span>
    </div>
    <div class="d-flex flex-row justify-content-space-between listing-details l2-search-btns-col">
      <button type="button" *ngIf="listing.freeHistoryReport" class="l2-search-btns-col-btn w-100 btn btn-dark btn-sm l2-btn-dark" (click)="carFaxService.pullCarFaxReport(listing.classifiedAdID)" ngbTooltip="A free history report may be available for this vehicle. Click to download.">Get Free {{listing.freeHistoryReport}}</button>
      <button type="button" *ngIf="!listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" class="l2-search-btns-col-btn w-100 btn btn-dark btn-sm l2-btn-dark" (click)="requestVehicleHistory()" (auxclick)="requestVehicleHistory()" ngbTooltip="Check if a free vehicle history report is available for this vehicle.">Request {{configService.vehicleHistoryRequestConfig.reportName}}</button>
      <button type="button" *ngIf="!listing.freeHistoryReport && !configService.vehicleHistoryRequestConfig" class="l2-search-btns-col-btn w-100 btn btn-outline-primary btn-sm l2-btn-outline" (click)="checkAvailability()">Check Availability</button>
      <button type="button" *ngIf="listing.freeHistoryReport || !listing.freeHistoryReport && configService.vehicleHistoryRequestConfig && !listing.isNew" class="l2-search-btns-col-btn w-100 btn btn-outline-primary btn-sm l2-btn-outline" (click)="checkAvailability()">Check Availability</button>
    </div>

    <div class="d-flex justify-content-space-between l2-search-btns-col">
      <div ngbDropdown class="l2-search-btns-col-btn" *ngIf="!configService.virtualCarSaleConfig?.id">
        <button ngbDropdownToggle class="btn btn-outline-dark w-100 find-better-price-btn l2-btn-prim" [ngbPopover]="fbpContent" triggers="mouseenter:mouseleave" popoverTitle="How It Works" [openDelay]="100" placement="right-bottom l2-btn-prim" container="body" [autoClose]="false">Find Better Price </button>
        <div ngbDropdownMenu>
          <button (click)="doFindBetter('state')" ngbDropdownItem>Search within entire state of {{listing.location.state}}</button>
          <button (click)="doFindBetter('regional')" ngbDropdownItem>Search within {{FIND_BETTER_PRICE_REGION}} miles of {{userSessionService.zipCode}}</button>
          <button (click)="doFindBetter('nationwide')" ngbDropdownItem>Search nationwide</button>
        </div>
      </div>
      <button type="button" class="l2-search-btns-col-btn w-100 btn btn-primary go-to-listing ml-1 l2-btn-darkoutline" type="submit" (click)="viewListing($event)" (auxclick)="viewListing($event)">More Details & Pictures</button>
    </div>
  </div>
  <!-- close small screen -->
</div>
<ng-template #fbpContent>
  <p>Did you know you may be able to save hundreds to thousands of dollars if you are willing to drive a few hours to find a better deal or have the vehicle shipped to your door by a professional vehicle delivery service?</p>
  <p class="mb-0">Use the <b>Find Better Price</b> tool to search the state, region, or entire nation for the best priced {{listing.make}} {{listing.model}} {{listing.trim}} listings with mileage and model years similar to or better than the listing above.</p>
</ng-template>