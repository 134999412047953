import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { ConfigService } from '@services/config.service';
import { SearchService } from '@services/search.service';
import { VehicleListing } from '@models/vehicle-listing';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    VehicleSearchSharedModule
  ],
  selector: 'app-vehicle-history-request-page',
  standalone: true,
  templateUrl: './vehicle-history-request-page.component.html',
  styleUrls: ['./vehicle-history-request-page.component.sass']
})
export class VehicleHistoryRequestPageComponent implements OnInit {

  listing!: VehicleListing;

  constructor(
    public searchService: SearchService,
    public configService: ConfigService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    const classifiedAdID = this.route.snapshot.paramMap.get('id');
    if (classifiedAdID) {
      this.searchService.getListing(classifiedAdID).subscribe(listing => this.listing = listing);
    }
  }

}
