import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { AFGService } from '@services/afg.service';
import { CarFaxService } from  '@services/car-fax.service';
import { ConfigService } from '@services/config.service';
import { ListingClick } from '@models/listing-click';
import { FindBetterListingsSearch } from '@services/search.service';
import { UserSessionService } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';
import { VTDButtonService } from '@services/vtd-button.service';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';
import { NewListingFlagComponent } from '../new-listing-flag/new-listing-flag.component';

@Component({
  imports: [CommonModule, NewListingFlagComponent, RouterModule, VehicleSearchSharedModule],
  selector: 'app-search-result-multicol',
  standalone: true,
  templateUrl: './search-result-multicol.component.html',
  styleUrls: ['./search-result-multicol.component.sass']
})
export class SearchResultComponentmulticol implements OnInit {

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  @Input() listing!: VehicleListing;
  @Output() checkAvailabilityView: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestVehicleHistoryView: EventEmitter<string> = new EventEmitter<string>();
  @Output() compareView: EventEmitter<string> = new EventEmitter<string>();
  @Output() findBetter: EventEmitter<FindBetterListingsSearch> = new EventEmitter<FindBetterListingsSearch>();
  @Output() listingView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();
  @Output() loansView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();

  // Changes to this value need to be coordinated with CarBuyingServiceImpl.FIND_BETTER_PRICE_REGION
  readonly FIND_BETTER_PRICE_REGION = 500;

  constructor(
    public afg: AFGService,
    public carFaxService: CarFaxService,
    public configService: ConfigService,
    public userSessionService: UserSessionService,

    private vtd: VTDButtonService,

  ) { }

  ngOnInit() {
  }

  checkAvailability() {
    this.checkAvailabilityView.emit(this.listing.classifiedAdID);
  }

  doFindBetter(locationRestriction: string) {
    this.findBetter.emit({
      selectedVehicle: this.listing,
      locationRestriction: locationRestriction,
    });
  }

  requestVehicleHistory() {
    this.requestVehicleHistoryView.emit(this.listing.classifiedAdID);
  }

  viewListing(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.listingView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  viewLoans(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.loansView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  getHasAFG() {
    if (this.configService.balloonLoanProgramName != null) {
      return true;
    } else {
      return false;
    }
  }
  
  showAfgBadge(): Observable<boolean> {
    return this.afg.isAfgEligible(this.listing);
  }
  
  // details
  playVtd() {
    if (this.listing.moreDetail && this.listing.moreDetail.vtdUrl) {
      this.vtd.play(this.listing.moreDetail.vtdUrl, this.listing.classifiedAdID);
    }
  }

}
