import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Params, Router, RouterModule } from "@angular/router";
import { filter } from 'rxjs/operators';

import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AFGService } from "@services/afg.service";
import { ConfigService,isKeyOfVCSFilters } from "@services/config.service";
import { EndUsersService, SavedSearch } from "@services/end-users.service";
import { CreditScoreTableRateService, PaymentParameters, PaymentService, Rate } from "@services/payment.service";
import { MonthlyPaymentParameters } from '../../services/lead.service';
import { SearchService } from "@services/search.service";
import { ToastService } from "@services/toast.service";
import { UserSessionService } from "@services/user-session.service";
import { CustomFilter } from "@models/vehicle-listing";

export interface SearchRequest {
  searchFormData: SearchFormData;
  searchFormDiffFromDefault: object;
  searchFormPaymentParams: PaymentParameters;
}

export interface ExtendedSearchFormData extends SearchFormData {
  monthlyPaymentParameters?: MonthlyPaymentParameters;
}

// Notes:
// Some OmniCommander widgets build calls directly to the /search route
// which uses these property names as parameters. Do not change condition/makes/models/zip
// without coordinating with OmniCommander.
//
// These search parameter names are used on the server-side when processing search requests.
// Any changes to the parameters here should be coordinated there as well. See
// the SearchParameters case class and CarBuyingServiceController.findVehicles.
//
// The user's most recent search may be persisted to localStorage as a JSON string
// representation of a SearchFormData object. Frontend widgets may read those persisted objects.
// Be careful about changing the definition of this interface without reviewing how
// the widgets depend on it.
export interface SearchFormData {
  condition: string;
  makes: string[];
  models: string[];
  bodyStyles: string[];
  radius: string;
  zip: string;
  priceOrMonthlyPayment: string;
  minPayment: string;
  maxPayment: string;
  minPrice: string;
  maxPrice: string;
  conventionalOrBalloonLoan: string;
  loanTypeEligibility: string;
  sortBy: string;
  maxMileage: string;
  exteriorColors: string[];
  interiorColors: string[];
  cityMpg: string;
  hwyMpg: string;
  transmission: string;
  driveTrain: string;
  cargoCapacity: string;
  daysOnMarket: string;
  cylinders: string[];
  doorCount: string;
  fuelType: string;
  minYear: string;
  maxYear: string;
  salvage: string;
  keywords: string;
  pageNum: string;
  vehicleType: string;

  // Client-side only; not used by server search
  searchOnLoad: string;
}

@Component({
  imports: [CommonModule, NgbCollapseModule, NgbModule, ReactiveFormsModule, RouterModule],
  selector: "app-search-form",
  standalone: true,
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.sass"],
})
export class SearchFormComponent implements OnInit {
  isAdvancedCollapsed!: boolean;
  isFormCollapsed!: boolean;
  isSalvageSearchAllowed!: boolean;
  typesMakesModelsBodies!: Record<string, Record<string, Record<string, string[]>>>;
  searchForm!: FormGroup;
  defaultSearchOptions!: Record<string, string>;
  customFilters: CustomFilter[] = [];

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  // Same search radius values used in Autolink.io app/models/CarBuyingService.scala
  searchRadiusOptions: number[] = [25, 35, 50, 100, 150, 250, 500];

  // Holds the current year, automatically updates based on the system's time.
  private currentYear = new Date().getFullYear();
  // Set the start year for the search to the next year after the current year.
  private startYear = this.currentYear + 1;
  // Fixed end year for the search, set to 1980.
  private endYear = 1980;
  // Creates an array of years for the search dropdown, starting from the start year and 
  // counting down to the end year, inclusive.
  searchYears: number[] = Array.from({ length: this.startYear - this.endYear + 1 }, (_, i) => this.startYear - i);

  // Generates an array of maximum mileage options for the search, ranging from 10,000 to 
  // 200,000, with increments of 10,000.
  searchMaxMileages: number[] = Array.from({ length: 20 }, (_, i) => 10000 * (i + 1));

  // Generates an array of price options for the search with three tiers:
  // 1. From $1,000 to $20,000, with increments of $1,000.
  // 2. From $25,000 to $120,000, with increments of $5,000.
  // 3. From $130,000 to $300,000, with increments of $10,000.
  searchPrices: number[] = [
    ...Array.from({ length: 20 }, (_, i) => 1000 * (i + 1)),
    ...Array.from({ length: 20 }, (_, i) => 5000 * (i + 5)),
    ...Array.from({ length: 18 }, (_, i) => 10000 * (i + 13)),
  ];

  @Output() newSearch = new EventEmitter<SearchRequest | null>();

  private isPatchingSearchFormFromURL: boolean = false;

  constructor(
    public afg: AFGService,
    public userSessionService: UserSessionService,
    private configService: ConfigService,
    private endUsersService: EndUsersService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    // Start with the search form expanded (note: this settings is only applied on small devices)
    this.isFormCollapsed = false;

    // Start with the advanced seach options collapsed (applied on all screen sizes)
    this.isAdvancedCollapsed = true;

    this.configService.allowSalvageSearch$.subscribe(
      (allow) => (this.isSalvageSearchAllowed = allow)
    );

    // the default minimum year should be 5 years prior to the current year
    // (determined by Auto Link team consensus)
    const minYear = new Date().getFullYear() - 5;

    this.searchForm = this.formBuilder.group({
      condition: ["All", [this.notBlankValidator]],
      makes: [["All"], [this.notBlankValidator]],
      models: [["All"], [this.notBlankValidator]],
      bodyStyles: [["All"], [this.notBlankValidator]],
      radius: ["35", [this.notBlankValidator]],
      zip: [this.userSessionService.zipCode, [this.notBlankValidator]],
      priceOrMonthlyPayment: ["price"],
      minPayment: ["0", [this.notBlankValidator]],
      maxPayment: ["0", [this.notBlankValidator]],
      minPrice: ["0", [this.notBlankValidator]],
      maxPrice: ["0", [this.notBlankValidator]],
      conventionalOrBalloonLoan: ["conventional"],
      loanTypeEligibility: ["All", [this.notBlankValidator]],
      sortBy: ["Default"],
      maxMileage: ["80000", [this.notBlankValidator]], // myEZ data shows ~75% of used vehicles are bought with under this many miles
      exteriorColors: [["All"], [this.notBlankValidator]],
      interiorColors: [["All"], [this.notBlankValidator]],
      cityMpg: ["0", [this.notBlankValidator]],
      hwyMpg: ["0", [this.notBlankValidator]],
      transmission: ["All", [this.notBlankValidator]],
      driveTrain: ["All", [this.notBlankValidator]],
      cargoCapacity: ["0", [this.notBlankValidator]],
      daysOnMarket: ["", [this.notBlankValidator]],
      cylinders: [["All"], [this.notBlankValidator]],
      doorCount: ["All", [this.notBlankValidator]],
      fuelType: ["All", [this.notBlankValidator]],
      minYear: [minYear.toString(), [this.notBlankValidator]],
      maxYear: ["0", [this.notBlankValidator]],
      salvage: ["No"],
      keywords: ["", [this.notBlankValidator]],
      pageNum: ["1"],
      searchOnLoad: ["false"],
      vehicleType: ["Auto", [this.notBlankValidator]],
    });

    // Initialize with blank data. This will be filled in once the search service returns data.
    this.typesMakesModelsBodies = {};

    this.searchService.getMakesModels().subscribe((typesMakesModelsBodies: { [key: string]: { [key: string]: { [key: string]: string[] } } }) => {
      this.typesMakesModelsBodies = typesMakesModelsBodies;
    });

    // Watch for changes to the default search radius and update the form if a new value comes in
    // Note: this effectively watches for when the config settings are received from the backend,
    // since the only thing that changes the default search radius is receiving the client's config.
    // 
    // Any code that needs to make use of the client's config settings should go in here.
    this.configService.defaultSearchRadius$.subscribe((radius) => {
      // Apply any virtual car sale filters to the search form, so that the user cannot search for vehicles that are
      // filtered out by the client. (Note: these filters are applied on the backend as well.)
      //
      // First, define comparison functions for greater than or equal to and less than or equal to
      const gte = (a: number, b: number) => a >= b;
      const lte = (a: number, b: number) => a <= b;
      // Apply each filter by passing the filter name, array to filter, the corresponding form control name, and the comparison function
      this.applyVCSSearchFilter('maxMileage', 'searchMaxMileages', 'maxMileage', lte);
      this.applyVCSSearchFilter('maxPrice', 'searchPrices', 'maxPrice', lte);
      this.applyVCSSearchFilter('minPrice', 'searchPrices', 'minPrice', gte);
      this.applyVCSSearchFilter('oldestModelYear', 'searchYears', 'minYear', gte);
      // The make and body style filters are applied server-side
      


      // The default search radius change triggers when the config service receives the config details from the server.
      // We can use this to update the custom filters, which are displayed in the search form.
      // We only need the list of filters here, not the map from filter name to filter object
      // Note: the sort order here matters. It must match the order - including the .filter() - as used on the back end.
      // This is because Angular will send only boolean values for the custom filters, and the server needs to match them up with the
      // custom filters in the same order.
      this.customFilters = Object.values(this.configService.customFilters).filter(cf => cf.formLabel !== null).sort((a, b) => a.filterId - b.filterId);
      // The customFilters control is added here instead of above since above the customFilters data isn't available there
      this.searchForm.addControl('customFilters', this.formBuilder.array(this.customFilters.map(() => false)));
      
      // Only process the radius if we have not already done so. This ensures that we don't subscribe
      // to the route params more than once. (Further, there should be no reason for the default
      // search radius to be sent to us here more than once.)
      if (this.defaultSearchOptions === undefined) {
        // Update the search form with the new radius
        this.searchForm.patchValue({ radius: radius.toString() });

        // Record the default search options, which are used in various
        this.defaultSearchOptions = this.searchForm.value;

        // Watch for changes to the URL parameters and trigger a new search if they change
        this.route.params.subscribe((params: Params) => {
          // The arrays in the search parameters come in stringified. Parse them so we can apply them to the search form correctly.
          const searchParamsParsed = this.paramParse(params);

          // Trigger a search automatically if there are search parameters in the URL
          if (Object.keys(searchParamsParsed).length > 0) {
            // We only want to trigger a new search if the parameters have changed to something other than what is in the search form
            // Without this condition, each search form submission would trigger two searches
            if (
              JSON.stringify(searchParamsParsed) !== JSON.stringify(this.getChangedFormValues())
            ) {
              // Reset the form to the default values, then apply any changes received from the URL parameters over top
              // We do the reset to the default first otherwise certain user paths through the site can result in the wrong search
              // options being applied. For example, View Saved Searches -> choose a saved search with several search options ->
              // return to View Saved Searches -> choose a different saved search with different search options. Any search parameters
              // from the first search which are not overwritten by the second search would still be applied in the second search,
              // when they should actually not be used at all.
              this.isPatchingSearchFormFromURL = true;
              this.searchForm.setValue(this.defaultSearchOptions);
              this.searchForm.patchValue(searchParamsParsed);
              this.isPatchingSearchFormFromURL = false;

              // We only want to reset the page number if pageNum is not part of the parameters since
              // it not being part of the parameters would indicate that we want to use the default value.
              const resetPageNum = searchParamsParsed["pageNum"] === undefined;

              this.doSearch(resetPageNum, searchParamsParsed);
            }
          } else {
            // Return the user back to the blank search page
            // We do this since it is what the user would expect by clicking the back button to return
            this.searchForm.setValue(this.defaultSearchOptions);
            this.newSearch.emit(null);
          }
        });
      }
    });

    // Attach event handlers to the search form's select input controls to react to value changes.
    this.attachSelectInputChangeHandlers();
  }

  doSearch(resetPageNum: Boolean = true, searchFormDiffFromDefault: Object | null = null) {
    if (resetPageNum) {
      // Reset the page number if needed
      // This ensures that the page can be set back to 1 on new searches
      this.searchForm.value.pageNum = this.defaultSearchOptions["pageNum"];
    }

    // Pick the correct payment service to use to get the payment parameters, which
    // we need to send to the server along with the search parameters.
    //
    // To pick the payment service, we need to know if this is a balloon loan search.
    //
    // There are two ways to do a balloon loan search:
    const isBalloonLoanSearch =
       // (1) using the loan type eligibility filter:
      this.searchForm.value.loanTypeEligibility === 'BalloonEligibleFilter'
        // (2) or by doing a monthly payment search for a balloon loan payment amount:
        || (this.searchForm.value.priceOrMonthlyPayment === 'payment' && this.searchForm.value.conventionalOrBalloonLoan === 'balloon');

    // Now we can pick the payment service
    const searchFormPaymentParams = (() => {
      if (isBalloonLoanSearch) {
        // Copy the default payment service and turn it into a balloon loan payment service:
        const balloonPaymentServiceParams = {...this.paymentService.params$.value};
        // We can safely assume the rateService is a CreditScoreTableRateService since the only way
        // isBalloonLoanSearch can be true is when we are using a CreditScoreTableRateService.
        // In other words, CreditScoreTableRateService means AFG is enabled.
        const rateService = balloonPaymentServiceParams.rateService as CreditScoreTableRateService;
        balloonPaymentServiceParams.rateService = new CreditScoreTableRateService(
          rateService.fallbackInterestRate,
          rateService.currentCreditTierScore,
          rateService.creditTiers,
          true, // isBalloonLoan <-- this is what we change to enable use of the balloon loan rate sheet
          rateService.rateRows,
          rateService.availableTerms,
        );
        return balloonPaymentServiceParams;
      } else {
        // Use the conventional payment service
        return this.paymentService.params$.value;
      }
    })();

    // Reasons for including each property here:
    // searchFormData            - obviously this is needed in order to send the search filters to the server
    // searchFormDiffFromDefault - not sent to the server but is used to set the parameters in the URL
    // searchFormPaymentParams   - sent to the server so it can be used in performing searches by monthly payment and in calculating debt protection product prices)
    const searchRequest: SearchRequest = {
      searchFormData: this.searchForm.value,
      searchFormDiffFromDefault: searchFormDiffFromDefault || this.getChangedFormValues(),
      searchFormPaymentParams: searchFormPaymentParams,
    };

    // Collapse the search form (note: this only affects the view on small devices)
    this.isFormCollapsed = true;

    this.newSearch.emit(searchRequest);
  }

  getMatchingMakes() {
    const selectedType = this.searchForm.value.vehicleType;

    if (selectedType === "All" || !this.typesMakesModelsBodies[selectedType]) {
      // Make sure the list of makes/models/bodies has been loaded
      // Match all available makes
      const matchedMakes = Object.keys(this.typesMakesModelsBodies).reduce(
        (makes: Array<string>, vehicleType: string) => {
          // Add all the makes for this particular vehcle type to the list of matched makes
          return makes.concat(Object.keys(this.typesMakesModelsBodies[vehicleType]));
        },
        []
      );

      return matchedMakes.sort();
    } else {
      // Otherwise just return
      return Object.keys(this.typesMakesModelsBodies[selectedType]).sort();
    }
  }

  /**
   * Retrieves a `FormControl` instance for a specific custom filter based on its index.
   * This function is utilized within the template HTML file to bind each custom filter's
   * checkbox to its corresponding form control within the `customFilters` FormArray.
   * This allows for dynamic generation and control of custom filters in the search form.
   *
   * @param i The index of the custom filter within the `customFilters` FormArray.
   * @returns The `FormControl` instance for the specified custom filter, or `null` if not found.
   */
  getCustomFilterFormControl(i: number): FormControl | null {
    const customFilter = this.searchForm.get('customFilters');
    if (customFilter instanceof FormArray) {
      return customFilter.at(i) as FormControl;
    } else {
      return null;
    }
  }

  getMatchingBodyStyles() {
    // Only try to figure out which body styles match if we have actually loaded in the list of makes/models
    if (!this.typesMakesModelsBodies || Object.keys(this.typesMakesModelsBodies).length === 0) {
      return [];
    } else {
      // Figure out which type(s) the user has selected.
      // They can either select one type or choose 'All', which acts as if all types were selected.
      const selectedTypes = (() => {
        if (this.searchForm.value.vehicleType === "All") {
          return Object.keys(this.typesMakesModelsBodies);
        } else {
          return this.searchForm.value.vehicleType;
        }
      })();

      // Also figure out which makes the user has selected.
      const selectedMakes = (() => {
        if (this.searchForm.value.makes.length === 1 && this.searchForm.value.makes[0] === "All") {
          // Walk the tree of types/makes and collect all the make names
          return Object.keys(this.typesMakesModelsBodies).reduce(
            (allMakes: Array<string>, vehicleType: string) => {
              return allMakes.concat(Object.keys(this.typesMakesModelsBodies[vehicleType]));
            },
            []
          );
        } else {
          return this.searchForm.value.makes;
        }
      })();

      // And figure out which models the user has selected.
      const selectedModels = (() => {
        if (
          this.searchForm.value.models.length === 1 &&
          this.searchForm.value.models[0] === "All"
        ) {
          // Walk the tree of types/makes/models and collect all the model names for the selected types/makes
          return Object.keys(this.typesMakesModelsBodies).reduce(
            (modelsForSelectedVehicleTypesAggregator: Array<string>, vehicleType: string) => {
              if (selectedTypes.indexOf(vehicleType) !== -1) {
                const modelsForSelectedMakes = Object.keys(
                  this.typesMakesModelsBodies[vehicleType]
                ).reduce((modelsForSelectedMakesAggregator: Array<string>, make: string) => {
                  if (selectedMakes.indexOf(make) !== -1) {
                    return modelsForSelectedMakesAggregator.concat(
                      Object.keys(this.typesMakesModelsBodies[vehicleType][make])
                    );
                  } else {
                    return modelsForSelectedMakesAggregator;
                  }
                }, []);
                return modelsForSelectedVehicleTypesAggregator.concat(modelsForSelectedMakes);
              } else {
                return modelsForSelectedVehicleTypesAggregator;
              }
            },
            []
          );
        } else {
          return this.searchForm.value.models;
        }
      })();

      // Finally, we extract the body styles for all the selected types, makes, and models.
      // We do this by walking the entire tree of types/make/models/body styles and picking out
      // the body styles that correspond to selected types/makes/models.
      const matchingBodyStyles = Object.keys(this.typesMakesModelsBodies).reduce(
        (bodyStylesForSelectedTypesAggregator: Array<string>, vehicleType: string) => {
          if (selectedTypes.indexOf(vehicleType) !== -1) {
            const bodyStylesForSelectedMake = Object.keys(
              this.typesMakesModelsBodies[vehicleType]
            ).reduce((bodyStylesForSelectedMakesAggregator: Array<string>, make: string) => {
              if (selectedMakes.indexOf(make) !== -1) {
                const bodyStylesForSelectedModels = Object.keys(
                  this.typesMakesModelsBodies[vehicleType][make]
                ).reduce((bodyStylesForSelectedModelsAggregator: Array<string>, model: string) => {
                  if (selectedModels.indexOf(model) !== -1) {
                    return bodyStylesForSelectedModelsAggregator.concat(
                      this.typesMakesModelsBodies[vehicleType][make][model]
                    );
                  } else {
                    return bodyStylesForSelectedModelsAggregator;
                  }
                }, []);
                return bodyStylesForSelectedMakesAggregator.concat(bodyStylesForSelectedModels);
              } else {
                return bodyStylesForSelectedMakesAggregator;
              }
            }, []);

            return bodyStylesForSelectedTypesAggregator.concat(bodyStylesForSelectedMake);
          } else {
            return bodyStylesForSelectedTypesAggregator;
          }
        },
        []
      );

      // Return a sorted list of unique body styles
      return [...new Set(matchingBodyStyles)].sort();
    }
  }

  getMatchingModels() {
    // Only try to figure out which models match if we have actually loaded in the list of makes/models
    if (!this.typesMakesModelsBodies || Object.keys(this.typesMakesModelsBodies).length === 0) {
      return [];
    } else {
      // Figure out which type(s) the user has selected.
      // They can either select one type or choose 'All', which acts as if all types were selected.
      const selectedTypes = (() => {
        if (this.searchForm.value.vehicleType === "All") {
          return Object.keys(this.typesMakesModelsBodies);
        } else {
          return this.searchForm.value.vehicleType;
        }
      })();

      // Also figure out which makes the user has selected.
      // We treat the selection of 'All' as if no make was selected.
      // This is because we only want to match to specific models if one
      // or more specific makes were explicitly selected by the user.
      // Otherwise, the Model input selector would be filled with an
      // overwhelming list of models.
      const selectedMakes = (() => {
        if (this.searchForm.value.makes.length === 1 && this.searchForm.value.makes[0] === "All") {
          return [];
        } else {
          return this.searchForm.value.makes;
        }
      })();

      // Finally, we extract the model names for all the selected types and makes
      const matchingModels = Object.keys(this.typesMakesModelsBodies).reduce(
        (matchingModelsAggregator: Array<string>, vehicleType: string) => {
          const modelsForThisType = Object.keys(this.typesMakesModelsBodies[vehicleType]).reduce(
            (modelsForThisTypeAggregator: Array<string>, make: string) => {
              // Is this type and make pair one that was selected by the user?
              if (selectedTypes.indexOf(vehicleType) !== -1 && selectedMakes.indexOf(make) !== -1) {
                // If so, add all its model names to our aggregation of matching models
                const modelNames = Object.keys(this.typesMakesModelsBodies[vehicleType][make]);
                return modelsForThisTypeAggregator.concat(modelNames);
              } else {
                return modelsForThisTypeAggregator;
              }
            },
            []
          );
          return matchingModelsAggregator.concat(modelsForThisType);
        },
        []
      );

      // Return a sorted list of unique model names
      return [...new Set(matchingModels)].sort();
    }
  }

  getVehicleTypes() {
    return Object.keys(this.typesMakesModelsBodies).sort();
  }

  getHasAFG() {
    if (this.configService.balloonLoanProgramName != null) {
      return true;
    } else {
      return false;
    }
  }

  resetForm() {
    this.searchForm.patchValue(this.defaultSearchOptions);
    return false; // do not trigger form submission
  }

  saveSearch() {
    const searchData = this.getChangedFormValues();
    if (Object.keys(searchData).length === 0) {
      this.toastService.showToast("Select one or more search options first", "darkred");
    } else {
      this.endUsersService.saveSearch(searchData).subscribe({
        next: (savedSearchID) => {
          const newSavedSearch: SavedSearch = {
            savedSearchID: savedSearchID,
            searchData: searchData,
          };
          this.endUsersService.savedSearches$.next(newSavedSearch);
          this.toastService.showToast("Search saved", "darkgreen");
        },
        error: () => this.toastService.showToast("Error saving search", "darkred"),
      });
    }
  }

  /**
   * Method to apply a filter to an array and update the form control if needed.
   * @param {string} filterName - The name of the filter to apply.
   * @param {string} searchArray - The key name of the array to filter.
   * @param {string} formControlName - The form control name to update if necessary.
   * @param {Function} comparisonFn - The comparison function to use for filtering.
   */
  private applyVCSSearchFilter(filterName: string, searchArray: string, formControlName: string, comparisonFn: (a: number, b: number) => boolean): void {
    // Safely get the filter value from the configuration
    if (isKeyOfVCSFilters(filterName, this.configService.virtualCarSaleConfig?.filters)) {
      const filterValue = this.configService.virtualCarSaleConfig?.filters?.[filterName];

      // Only proceed if the request is valid
      if (
        filterValue != null &&
        typeof filterValue === "number" && // only number comparisons are supported here at this time
        this.isKeyOfSearchFormComponent(searchArray)
      ) {
        // Apply the filter to the array using the comparison function
        this[searchArray] = this[searchArray].filter((item: any) => comparisonFn(item, filterValue));

        // Get the current value from the form
        const formValue = this.searchForm.value[formControlName];

        // Check if the form value needs to be updated based on the comparison function logic
        if (formValue > 0 && comparisonFn(filterValue, formValue)) {
          // Update the form control with the filter value
          this.searchForm.patchValue({ [formControlName]: filterValue });
        }
      }
    }
  }

  /**
   * Check if a key is a key of the SearchFormComponent
   * @param key - The key to check
   * @returns - True if the key is a key of the SearchFormComponent
   */
  private isKeyOfSearchFormComponent(key: string): key is keyof SearchFormComponent {
    return key in this;
  }

  stringToId(str: string): string {
    return str.trim().toLowerCase().replace(/[^a-z0-9]+/g, "-");
  }

  /**
   * Attaches event handlers to the search form's select input controls to react to value changes.
   * The purpose of this function is to handle the special behavior triggered by the 'All' option
   * in certain drop down select fields.
   *
   * It is important that this function only passes value changes through to the event handlers
   * if the search form is not being patched with new values from the URL. This is because if those
   * new values were passed through to the change handlers it would cause problems such as
   * the vehicle type change handler being triggered and resetting the makes input, which is not
   * desired if a make value is also being loaded into the makes input.
   */
  private attachSelectInputChangeHandlers() {
    this.searchForm.controls['vehicleType'].valueChanges
      .pipe(filter(() => !this.isPatchingSearchFormFromURL))
      .subscribe((newVal: any) => this.changedVehicleType(newVal));

    this.searchForm.controls['makes'].valueChanges
      .pipe(filter(() => !this.isPatchingSearchFormFromURL))
      .subscribe((newVal: any) => this.changedMake(newVal));

    this.searchForm.controls['models'].valueChanges
      .pipe(filter(() => !this.isPatchingSearchFormFromURL))
      .subscribe((newVal: any) => this.changedModel(newVal));

    this.searchForm.controls['bodyStyles'].valueChanges
      .pipe(filter(() => !this.isPatchingSearchFormFromURL))
      .subscribe((newVal: any) => this.changedBodyStyle(newVal));

    this.searchForm.controls['exteriorColors'].valueChanges
      .pipe(filter(() => !this.isPatchingSearchFormFromURL))
      .subscribe((newVal: any) => this.changedExteriorColors(newVal));
  }

  private changedExteriorColors(newVal: string[]) {
    this.handleAllOption(newVal, "exteriorColors");
  }

  private changedBodyStyle(newVal: string[]) {
    this.handleAllOption(newVal, "bodyStyles");
  }

  private changedMake(newVal: string[]) {
    this.handleAllOption(newVal, "makes");
    this.searchForm.patchValue({
      models: ["All"],
      bodyStyles: ["All"],
    });
  }

  private changedModel(newVal: string[]) {
    this.handleAllOption(newVal, "models");
    this.searchForm.patchValue({
      bodyStyles: ["All"],
    });
  }

  private changedVehicleType(newVal: string[]) {
    this.searchForm.patchValue({
      makes: ["All"],
      models: ["All"],
      bodyStyles: ["All"],
      priceOrMonthlyPayment: "price"
    });
  }

  private getChangedFormValues() {
    return this.objDiff(this.defaultSearchOptions, this.searchForm.value);
  }

  private handleAllOption(newVal: Array<string>, formKey: string) {
    const patch: { [key: string]: string[] } = {};
    if (newVal.length === 0) {
      // Re-select All if no other options are selected
      patch[formKey] = ["All"];
      this.searchForm.patchValue(patch);
    } else if (newVal.length > 1) {
      const oldVal = this.searchForm.value[formKey];
      const allIdxNew = newVal.indexOf("All");
      const allIdxOld = oldVal.indexOf("All");

      if (allIdxNew !== -1 && allIdxOld === -1) {
        // Deselect all other options if the user is now selecting All
        patch[formKey] = ["All"];
        this.searchForm.patchValue(patch);
      } else if (allIdxNew !== -1 && allIdxOld !== -1) {
        // Deselect All if the user had All selected but is now adding a second option
        newVal.splice(allIdxNew, 1);
        // We create a new array instead of copying the old one because otherwise angular does not update the form
        patch[formKey] = new Array(...newVal);
        this.searchForm.patchValue(patch);
      }
    }
  }

  // Returns a new object containing all of the properties in b which have been changed from the corresponding properties in a
  private objDiff(a: Record<string, string[] | string>, b: Record<string, string[] | string>) {
    return Object.keys(b)
      .filter((key) => {
        // Are these two property values not identical?
        if (Array.isArray(b[key]) && Array.isArray(a[key])) {
          // Arrays are identical if they have all the same elements
          const aArray = a[key] as Array<Object>;
          const bArray = b[key] as Array<Object>;
          return (
            bArray.length !== aArray.length ||
            !bArray.every((value, index) => value === aArray[index])
          );
        } else {
          // Other types are identical if === says they are identical
          return b[key] !== a[key];
        }
      })
      .reduce((acc: Record<string, string[] | string>, key: string) => {
        acc[key] = b[key];
        return acc;
      }, {});
  }

  // The parameters all come in as strings
  // We need to convert some of the strings into arrays of strings
  private paramParse(paramsStringified: { [key: string]: string }) {
    const paramsParsed: Record<string, (string | boolean) | (string | boolean)[]> = {};

    const convertValue = (value: string): string | boolean => {
      if (value === "true") return true;
      if (value === "false") return false;
      return value;
    };

    Object.keys(paramsStringified).forEach((key) => {
      const paramValue: string = paramsStringified[key];
      if (Array.isArray(this.defaultSearchOptions[key])) {
        paramsParsed[key] = paramValue.split(",").map(convertValue);
      } else {
        paramsParsed[key] = convertValue(paramValue);
      }
    });

    return paramsParsed;
  }

  // We use the Angular validator functionality to highlight form inputs that are not blank,
  // so the user can easily see which form input fields will affect their search
  private notBlankValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value != "" && control.value != "All" && control.value != "0") {
      return null;
    } else {
      return { "Not blank": { value: control.value } };
    }
  }

}
