<div class="vehicle-history-request-page px-4 pb-2" *ngIf="listing">
  <header class="bg-dark text-white mb-3">
    <div class="p-4 d-flex justify-content-between">
      <h1>Request {{configService.vehicleHistoryRequestConfig?.reportName}}</h1>
      <button type="button" class="btn btn-outline-light align-self-center" (click)="searchService.goToSRP()" ngbTooltip="Back to search results">&times;</button>
    </div>
    <app-image-slider [imageUrl]="listing.imageUrl" [routerLink]="['/listing', listing.classifiedAdID]" ngbTooltip="Click to view more pictures and details"></app-image-slider>
  </header>
  <h2 class="text-center">{{listing.year}} {{listing.make}} {{listing.model}} <span class="text-muted">{{listing.trim}}<span *ngIf="listing.isNew"> (New)</span></span></h2>
  <p *ngIf="configService.vehicleHistoryRequestConfig?.verbiage">{{configService.vehicleHistoryRequestConfig?.verbiage}}</p>
  <p *ngIf="!configService.vehicleHistoryRequestConfig?.verbiage">The free vehicle history report for this vehicle is not available for download online. However, you may submit a request to our credit union staff, and we will obtain a copy of the report and email it to you at no cost to you.</p>
  <app-lead-form [listing]="listing" [isVehicleHistoryRequest]="true"></app-lead-form>
</div>
  
<app-footer></app-footer>