import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { ConfigService } from '@services/config.service';
import { EndUsersService } from '@services/end-users.service';

@Component({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  selector: 'app-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('passwordResetInput', {static: false}) emailResetInput!: ElementRef;

  resetPasswordForm!: FormGroup;

  msg!: string;

  private resetKey!: string | null;

  constructor(
    private configService: ConfigService,
    private endUserService: EndUsersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
   ) { }

  ngOnInit() {
    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    this.resetKey = this.route.snapshot.paramMap.get('key');

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    setTimeout(() => this.emailResetInput.nativeElement.focus(), 750);
  }

  doSubmitNewPassword() {
    if (this.resetPasswordForm.status === 'VALID') {
      if (this.resetPasswordForm.value.password === this.resetPasswordForm.value.confirmPassword) {
        if (this.resetKey) {
          this.endUserService.saveNewPassword(this.resetKey, this.resetPasswordForm.value.password).subscribe({
            next: (msg) => {
              this.msg = msg;
            },
            error: (httpErrorResponse) => {
              this.msg = httpErrorResponse.error;
            },
          });
        } else {
          // This should never happen if our routes are set up correctly to require a key to access this component
          this.msg = 'Invalid password reset link. No reset key specified.';
        }
      } else {
        this.msg = 'Your passwords do not match';
      }
    }
  }

}
