<div class="lead-page px-4 pb-2" *ngIf="listing">
  <header class="bg-dark text-white mb-3">
    <div class="p-4 d-flex justify-content-between">
      <h1 class="">{{listing.year}} {{listing.make}} {{listing.model}} <span class="text-muted">{{listing.trim}}<span *ngIf="listing.isNew"> (New)</span></span></h1>
      <button type="button" class="btn btn-outline-light align-self-center" (click)="searchService.goToSRP()" ngbTooltip="Back to search results">&times;</button>
    </div>
    <app-image-slider [imageUrl]="listing.imageUrl" [routerLink]="['/listing', listing.classifiedAdID]" ngbTooltip="Click to view more pictures and details"></app-image-slider>
  </header>
  <app-no-financing-notice *ngIf="!configService.financingSupported(listing.vehicleType)" [listing]="listing"></app-no-financing-notice>
  <app-lead-form [listing]="listing"></app-lead-form>
</div>

<app-footer></app-footer>