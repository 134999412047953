<div class="soft-lead card">
  <form [formGroup]="contactForm" class="validated-form card-body" (submit)="submitContactInformation()">
    <div *ngIf="status === INIT">
      <div class="text-center"><img src="/assets/img/questions.png" alt="Have a question about buying a car?" class="header-img"></div>
      <p>Ask a question, and you will be entered into {{configService.cu.name}}'s Car Buying Sweepstakes!</p>

      <ng-container *ngTemplateOutlet="prize"></ng-container>

      <div class="mt-3 p-4 question border-primary">
        <h4 class="font-weight-bold h5">What question do you have about the car buying process?</h4>
        <div class="d-flex question-input-block">
          <div>
            <select class="custom-select" formControlName="question">
              <option>How much of a loan do I qualify for?</option>
              <option>I found a vehicle I like. What should I do next?</option>
              <option>Can you help me determine my monthly payment?</option>
              <option>What interest rate do I qualify for?</option>
              <option>How soon can I purchase a vehicle?</option>
              <option>What is my loan approval status?</option>
              <option value="custom">I have another question.</option>
            </select>
            <textarea id="customQuestionInput" formControlName="customQuestion" class="form-control mt-1" rows="4" placeholder="Enter your question here" *ngIf="contactForm.value.question === 'custom'"></textarea>
          </div>
          <button class="btn btn-primary" (click)="selectQuestion()">Submit</button>
        </div>
      </div>
    </div>



    <div *ngIf="status === FORM">
      <ng-container *ngTemplateOutlet="prize"></ng-container>

      <p class="mt-3 mb-1 font-weight-bold">Your Entry Information</p>
      <p class="text-muted small">Don't worry &mdash; the information you provide here will not be sold to third-parties and will only be used to notify you if you are the winner of the sweepstakes and/or to provide you the answer to your selected question.</p>

      <div class="form-row">
        <div class="col form-group">
          <label for="firstNameInput">First Name</label>
          <input id="firstNameInput" formControlName="firstName" class="form-control" required #firstNameInput>
        </div>
        <div class="col form-group">
          <label for="lastNameInput">Last Name</label>
          <input id="lastNameInput" formControlName="lastName" class="form-control" required>
        </div>
      </div>
      <div class="form-row">
        <div class="col form-group">
          <label for="emailAddressInput">Email Address</label>
          <input id="emailAddressInput" formControlName="emailAddress" class="form-control" type="email" required>
        </div>
        <div class="col form-group">
          <label for="phoneInput">Phone (optional)</label>
          <input id="phoneInput" formControlName="phoneNumber" class="form-control" type="tel" (keyup)="formatPhoneInContactForm()">
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <button type="submit" class="btn btn-primary mb-3" [disabled]="contactForm.invalid">Submit Your Entry!</button>
        <button class="btn btn-outline-primary mb-3" (click)="status = INIT">Back</button>
      </div>
      <p class="small m-0">Please limit one entry per day per person.</p>
    </div>



    <div *ngIf="status === DONE">
      <ng-container *ngTemplateOutlet="prize"></ng-container>

      <h1 class="mt-5 text-center">Got it &mdash; thank you!</h1>
    </div>
  </form>

  <div class="card-footer">
    <p class="small text-muted m-0">NO PURCHASE NECESSARY TO ENTER OR WIN. Purchasing does not increase your chance of winning. <a [href]="configService.sweepstakes.rulesUrl" target="_blank">Official Sweepstakes Rules Page</a>.</p>
  </div>
</div>

<ng-template #prize>
  <div class="d-flex justify-content-center prize mt-3">
    <div class="d-flex align-items-center">
      <img *ngIf="configService.sweepstakes.prizeDetailUrl === undefined" [src]="configService.sweepstakes.prizeImgUrl" alt="" class="prize-img">
      <a *ngIf="configService.sweepstakes.prizeDetailUrl !== undefined" [href]="configService.sweepstakes.prizeDetailUrl" target="_blank"><img [src]="configService.sweepstakes.prizeImgUrl" alt="" class="prize-img"></a>
    </div>
    <div class="d-flex flex-column justify-content-center">
      <h5 class="text-muted h4 mb-0">Current Prize</h5>
      <h5>
        <ng-container *ngIf="configService.sweepstakes.prizeDetailUrl === undefined">{{configService.sweepstakes.prize}}</ng-container>
        <a *ngIf="configService.sweepstakes.prizeDetailUrl !== undefined" [href]="configService.sweepstakes.prizeDetailUrl" target="_blank">{{configService.sweepstakes.prize}}</a>
      </h5>
    </div>
  </div>
</ng-template>