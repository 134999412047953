import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

import { ConfigService } from '@services/config.service';
import { EndUsersService, SavedSearch } from '@services/end-users.service';
import { ToastService } from '@services/toast.service';
import { SearchFormComponent, SearchRequest } from '@components/search-form/search-form.component';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    SearchFormComponent,
    VehicleSearchSharedModule
  ],
  selector: 'app-saved-searches-page',
  standalone: true,
  templateUrl: './saved-searches-page.component.html',
  styleUrls: ['./saved-searches-page.component.sass']
})
export class SavedSearchesPageComponent implements OnInit {

  savedSearches: SavedSearch[] = [];

  constructor(
  	private configService: ConfigService,
  	private endUsersService: EndUsersService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    this.endUsersService.savedSearches$.subscribe(savedSearch => this.savedSearches.push(savedSearch));
  }

  deleteSearch(savedSearchID: number) {
    this.endUsersService.savedSearchRemove(savedSearchID).subscribe({
      next: () => {
        const idx = this.savedSearches.findIndex(savedSearch => savedSearch.savedSearchID === savedSearchID);
        this.savedSearches.splice(idx, 1);

        this.toastService.showToast('Search deleted', 'darkgreen')
      },
      error: () => this.toastService.showToast('Error deleting search', 'darkred'),
    });
  }

  loadResults(searchRequest: SearchRequest | null) {
    if (searchRequest) {
      this.router.navigate(['/search', searchRequest.searchFormDiffFromDefault]);
    }
  }

  prettyPrintKey(searchKey: string) {
    return searchKey.split("").reduce((acc, nextLetter) => {
      if (acc.length === 1) {
        // The first letter should always be capitalized
        return acc.toUpperCase() + nextLetter;
      } else {
        if (nextLetter.toUpperCase() === nextLetter) {
          // Subsequent capitalized letters should be the start of a new word
          return acc + " " + nextLetter;
        } else {
          // Otherwise just concatenate
          return acc + nextLetter;
        }
      }
    });
  }

  searchKeys(search: SavedSearch) {
    return Object.keys(search.searchData);
  }

}
