import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule, Params } from '@angular/router';
import { Observable } from 'rxjs';

import { AFGService } from "@services/afg.service";
import { CompareService } from '@services/compare.service';
import { ConfigService } from '@services/config.service';
import { SearchService } from '@services/search.service';
import { ToastService } from '@services/toast.service';
import { VehicleListing } from '@models/vehicle-listing';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';

@Component({
  imports: [
    CommonModule,
    RouterModule,
    VehicleSearchSharedModule
  ],
  selector: 'app-compare-vehicles',
  standalone: true,
  templateUrl: './compare-vehicles.component.html',
  styleUrls: ['./compare-vehicles.component.sass']
})
export class CompareVehiclesComponent implements OnInit {

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  fullFeatureList: Map<string, boolean | string> = new Map(); // maps [listing ID + featureName] to feature values
  fullFeatureListNames: Array<string> = [];
  listings: Array<VehicleListing> = [];

  constructor(
    public afg: AFGService,
    public configService: ConfigService,
    public searchService: SearchService,
    private compareService: CompareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    // Watch for classified ad ID parameters and use them to populate the comparison table
    this.route.params.subscribe((params: Params) => {
      const idArrayStr = params['idArray'];
      if (idArrayStr) {
        const classifiedAdIDs = idArrayStr.split(',');
        this.compareService.getListings(classifiedAdIDs).subscribe(listings => {
          this.listings = listings;
          this.rebuildFullFeatureList();
        });
      }
    });
  }

  calcTableWidth() {
    return this.listings.length < 6 ? 1100 / (1 + this.listings.length) : 175;
  }

  removeVehicle(listingToRemove: VehicleListing) {
    if (this.listings.length > 1) {
      const classifiedAdIDs = this.listings.map(_ => _.classifiedAdID);
      const idx = classifiedAdIDs.findIndex(classifiedAdID => classifiedAdID === listingToRemove.classifiedAdID);
      classifiedAdIDs.splice(idx, 1);
      this.router.navigate(['/compareVehicles', classifiedAdIDs.toString()]);
    } else {
      this.toastService.showToast('Cannot delete last comparison', 'red');
    }
  }

  //check an array of listings to see if at least one of them has an actual value for the named property
  //bracket notation only allows for accessing a root property, so the moreDetail boolean is used when we want to access the moreDetail property's properties
  hasAtLeastOneAFG(listings: Array<VehicleListing>)
  {
    let hasInstance = false;
    for(const listing of listings)
    {
      if (this.afg.mayBeEligible(listing))
      {
        hasInstance = true;
      }
    }
    return hasInstance;
  }

  //check an array of listings to see if at least one of them has an actual value for the named property
  //bracket notation only allows for accessing a root property, so the moreDetail boolean is used when we want to access the moreDetail property's properties
  hasAtLeastOneInstance(listings: Array<VehicleListing>, property: string, moreDetail: boolean)
  {
    let hasInstance = false;
    for(const listing of listings)
    {
      if(moreDetail)
      {
        if(listing['moreDetail'] && listing['moreDetail'][property] && listing['moreDetail'][property].toString().trim() != '' && listing['moreDetail'][property] != -1)
        {
          hasInstance = true;
        }
      }
      else if(listing[property] && listing[property].toString().trim() != '' && listing[property] != -1)
      {
        hasInstance = true;
      }
    }
    return hasInstance;
  }

  private rebuildFullFeatureList() {
    this.fullFeatureList = new Map();
    this.fullFeatureListNames = [];
    this.listings.forEach(listing => {
      if (listing.moreDetail) {
        listing.moreDetail.featureList.forEach(feature => {
          const splitFeature = feature.split(': ')
          const featureName = splitFeature[0].split(' ').map(word => word.substring(0, 1).toUpperCase() + word.substring(1)).join(' ');
          // ^ uppercase every word in the feature name

          var featureValue: boolean | string;
          if (splitFeature[1]) {
            featureValue = splitFeature[1];
          } else {
            featureValue = true;
          }

          // There are a number of strange features which look like "XXX lbs." where the X's are digits
          // Are these reporting the weight of the vehicle? No, they seem much too low for that.
          // For now we filter these out because we don't know what they are supposed to be.
          if (featureName.indexOf('Lbs.') === -1) {
            if (this.fullFeatureListNames.indexOf(featureName) === -1) {
              this.fullFeatureListNames.push(featureName);
            }
            const featureMapKey = '#' + listing.id + '-' + featureName;
            this.fullFeatureList.set(featureMapKey, featureValue);
          }
        })
      }
    });
    // Sort the feature names alphabetically (case insensitive)
    this.fullFeatureListNames.sort((a, b) => {
      return a.trim().localeCompare(b.trim(), 'en', {sensitivity: 'base'});
    });
  }

}
