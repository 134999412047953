import { Routes } from '@angular/router';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SRPWrapperComponent } from './components/srp/srp-wrapper/srp-wrapper.component';
import { CompareVehiclesComponent } from './components/compare-vehicles/compare-vehicles.component';
import { LoginComponent } from './components/login/login.component';
import { LeadPageComponent } from './components/lead-page/lead-page.component';
import { VehicleHistoryRequestPageComponent } from './components/vehicle-history-request-page/vehicle-history-request-page.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SavedSearchesPageComponent } from './components/saved-searches-page/saved-searches-page.component';


export const routes: Routes = [
  // Deprecated routes
  { path: 'checkAvailability/:cu/:userZipCode/:id', redirectTo: 'checkAvailability/:id' },
  { path: 'search/:cu/:userZipCode', redirectTo: 'search' }, 

  // Should match CarBuyingService ROUTE_* properties
  { path: 'checkAvailability/:id', component: LeadPageComponent },
  { path: 'vehicleHistoryRequest/:id', component: VehicleHistoryRequestPageComponent },
  { path: 'search', component: SRPWrapperComponent  }, // note: some Omnicommander widgets build calls directly to this route
  { path: 'login', component: LoginComponent },
  { path: 'createAccount', component: LoginComponent },
  { path: 'resetPassword/:key', component: ResetPasswordComponent }, // Should match FollowupEmail.RESET_PASSWORD_ROUTE
  { path: 'compareVehicles/:idArray', component: CompareVehiclesComponent },
  { path: 'savedSearches', component: SavedSearchesPageComponent },
  { path: 'listing', loadChildren: () => import('./modules/vdp/vdp.module').then(m => m.VDPModule) }, // Should match CarBuyingService.generateListingUrl
  { path: 'loans', loadChildren: () => import('./modules/loan-selection/loan-selection.module').then(m => m.LoanSelectionModule) },
  { path: 'favoriteVehicles', loadChildren: () => import('./modules/favorite-vehicles/favorite-vehicles.module').then(m => m.FavoriteVehiclesModule) },
  { path: '**', component: PageNotFoundComponent },
];
