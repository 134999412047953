import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ConfigService } from '@services/config.service';
import { EndUsersService, SavedSearch } from '@services/end-users.service';
import { FavoriteVehiclesService } from '@services/favorite-vehicles.service';
import { SearchService } from '@services/search.service';
import { UserSessionService, UserSessionState } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';

@Component({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  @ViewChild('emailLoginInput', {static: false}) emailLoginInput!: ElementRef;
  @ViewChild('emailResetInput', {static: false}) emailResetInput!: ElementRef;
  @ViewChild('firstNameSignupInput', {static: false}) firstNameSignupInput!: ElementRef;

  readonly SHOW_CREATE: number = 2;
  readonly SHOW_CREATE2: number = 3;
  readonly SHOW_LOGIN: number = 0;
  readonly SHOW_RESET: number = 1;
  readonly SHOW_RESET2: number = 4;

  // Stores one of the status numbers from above
  status!: number;

  loginForm!: FormGroup;
  createAccountForm!: FormGroup;
  resetPasswordForm!: FormGroup;

  // Messages displayed to the user
  introMsg!: string;
  loginMsg!: string;
  createAccountMsg!: string;
  resetPasswordMsg!: string;

  private listingToSave?: VehicleListing;

  constructor(
    public searchService: SearchService,
    public userSessionService: UserSessionService,
    private configService: ConfigService,
    private endUserService: EndUsersService,
    private favoriteVehiclesService: FavoriteVehiclesService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      const state = navigation.extras.state as {msg: string, listingToSave?: VehicleListing};
      this.introMsg = state.msg;
      this.listingToSave = state.listingToSave;
    }
  }

  ngOnInit() {
    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    this.loginForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    
    this.createAccountForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
    
    this.resetPasswordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
    });

    if (this.router.url === "/createAccount") {
      this.viewCreateAccount();
    } else {
      this.viewLogin();
    }
  }

  doSubmitLogin() {
    if (this.loginForm.status === 'VALID') {
      this.endUserService.login(this.loginForm.value).subscribe({
        next: (loginResponse) => {
          if (loginResponse.isLoggedIn) {
            this.userSessionService.currentUser = loginResponse.userData;
            this.endUserService.favoriteVehicles$.next(loginResponse.favoriteVehicles);
            loginResponse.savedSearches.forEach((savedSearch: SavedSearch) => this.endUserService.savedSearches$.next(savedSearch));
            this.setLoggedInState();
            this.searchService.goToSRP();
          } else {
            this.loginMsg = 'Unknown error.';
          }
        },
        error: (httpErrorResponse) => {
          this.loginMsg = httpErrorResponse.error;
        },
      });
    }
  }

  doSubmitNewAccount() {
    if (this.createAccountForm.status === 'VALID') {
      if (this.createAccountForm.value.password === this.createAccountForm.value.confirmPassword) {
        this.endUserService.createUser(this.createAccountForm.value).subscribe({
          next: (msg) => {
            this.userSessionService.emailAddress = this.createAccountForm.value.emailAddress;
            this.setLoggedInState();
            this.status = this.SHOW_CREATE2;
          },
          error: (httpErrorResponse) => {
            this.createAccountMsg = httpErrorResponse.error;
          },
        });
      } else {
        this.createAccountMsg = 'Your passwords do not match';
      }
    }
  }

  doSubmitResetPassword() {
    if (this.resetPasswordForm.status === 'VALID') {
      this.endUserService.resetPassword(this.resetPasswordForm.value.emailAddress).subscribe({
        next: (msg) => {
          this.status = this.SHOW_RESET2;
        },
        error: (httpErrorResponse) => {
          this.resetPasswordMsg = httpErrorResponse.error;
        },
      });
    }
  }

  viewCreateAccount() {
    this.status = this.SHOW_CREATE;
    setTimeout(() => this.firstNameSignupInput.nativeElement.focus(), 750);
  }

  viewLogin() {
    this.status = this.SHOW_LOGIN;
    setTimeout(() => this.emailLoginInput.nativeElement.focus(), 750);
    // Allow enough time for the animation to complete otherwise some browsers (Safari on macOS)
    // display the auto-suggestions incorrectly
  }

  viewResetPassword() {
    this.status = this.SHOW_RESET;
    setTimeout(() => this.emailResetInput.nativeElement.focus(), 750);
  }

  private setLoggedInState() {
    this.userSessionService.state = UserSessionState.LoggedInState;
    if (this.listingToSave) {
      this.favoriteVehiclesService.save(this.listingToSave);
    }
  }

}
