import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

import { CarSaleRegistrationComponent } from '../car-sale-registration/car-sale-registration.component';
import { CompareService } from '../../services/compare.service';
import { ConfigService } from '../../services/config.service';
import { MonthlyPaymentParameters } from '../../services/lead.service';
import { ListingClick } from '../../models/listing-click';
import { ExtendedSearchFormData, SearchFormComponent, SearchFormData, SearchRequest } from '../../components/search-form/search-form.component';
import { FavoriteVehiclesModule } from '../../modules/favorite-vehicles/favorite-vehicles.module';
import { SoftLeadComponent } from '../soft-lead/soft-lead.component';
import { FindBetterListingsSearch, SearchService } from '../../services/search.service';
import { SearchResultComponent } from '../search-result/search-result.component';
import { SearchResult, VehicleListingResult, SoftLeadResult, CarSaleRegistrationLeadResult } from '../../models/search-result';
import { UserSessionService } from '../../services/user-session.service';
import { VehicleListing } from '../../models/vehicle-listing';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';

import { environment } from './../../../environments/environment';



@Component({
  imports: [
    CarSaleRegistrationComponent,
    CommonModule,
    FavoriteVehiclesModule,
    RouterModule,
    SearchFormComponent,
    SearchResultComponent,
    SoftLeadComponent,
    VehicleSearchSharedModule
  ],
  selector: 'app-srp',
  standalone: true,
  templateUrl: './srp.component.html',
  styleUrls: ['./srp.component.sass']
})
export class SRPComponent implements OnInit, AfterViewChecked {

  @ViewChild('signUpModal', {static: false}) signUpModal!: ElementRef;

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  currentSearch!: Record<string, string>;
  searchStatus!: number;
  searchResults!: SearchResult[]; // results within the radius that the user requested
  moreSearchResults!: SearchResult[]; // results outside the radius that the user requested
  listingCount!: number;
  resultCount!: number;
  searchRadius!: number;

  // searchStatus options
  readonly SEARCH_INIT = 0;
  readonly SEARCH_ACTIVE = 1;
  readonly SEARCH_STANDARD_COMPLETE = 2;
  readonly SEARCH_BEST_PRICE_COMPLETE = 3;

  private lastScrollPositionY!: number;
  private restoreScroll!: boolean;

  constructor(
    public configService: ConfigService,
    public userSessionService: UserSessionService,
    private changeDetectorRef: ChangeDetectorRef,
    private compareService: CompareService,
    private locationStrategy: LocationStrategy,
    private searchService: SearchService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.searchStatus = this.SEARCH_INIT;

    // Load the CU's configuration from the server if it is not already loaded
    this.configService.loadConfig();

    // Watch for the browser history state to pop (which indicates the user hit the browser back button from the VDP)
    // or for the srpAttachRequest$ observable to trigger (which means the user hit the Go to SRP button on the VDP).
    // When that happens, we want to restore the browser scroll position.
    this.locationStrategy.onPopState(() => this.restoreScroll = true);
    this.searchService.srpAttachRequest$.subscribe(() => {
      this.restoreScroll = true;

      // Re-attach the SRP and restore the current search options
      this.router.navigate(['/search', this.currentSearch]);
    });

    setTimeout(() => {
      if (this.signUpModal && !this.userSessionService.doNotShowSignUpModal) {
        this.signUpModal.nativeElement.classList.remove("d-none");
      }
    }, 2000);
  }

  ngAfterViewChecked() {
    // If the scroll position needs to be restored, go ahead and do that.
    // We do that from here instead of when the onPopState event occurs because onPopState happens before
    // the present component gets rendererd.
    if (this.restoreScroll && this.lastScrollPositionY) {
      setTimeout(() => { // delay the postMessage to give the iframe enough time to grow
        window.parent.postMessage({eventType: 'scrollTo', yOffset: this.lastScrollPositionY}, '*');
      }, 250);
      this.restoreScroll = false;
    }
  }

  currentPage() {
    return isNaN(+this.currentSearch['pageNum']) ? 1 : +this.currentSearch['pageNum'];
  }

  goToCheckAvailability(classifiedAdID: string) {
    this.saveLastPosition(classifiedAdID);
    this.router.navigate(['/checkAvailability', classifiedAdID]);
  }

  goToCompare(classifiedAdID: string) {
    this.saveLastPosition(classifiedAdID);
    this.router.navigate(['/compareVehicles', this.compareService.classifiedAdIDs]);
  }

  goToListing(listingClick: ListingClick) {
    const routerCommands = ['/listing', listingClick.classifiedAdID];
    // The reason for this indirect method of linking to the VDP is so we can store the scroll position
    this.saveLastPosition(listingClick.classifiedAdID);
    if (listingClick.newWindow) {
      window.open(this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString());
    } else {
      this.router.navigate(routerCommands);
    }
  }

  goToLoans(listingClick: ListingClick) {
    const routerCommands = ['/loans', listingClick.classifiedAdID];
    // The reason for this indirect method of linking to the VDP is so we can store the scroll position
    this.saveLastPosition(listingClick.classifiedAdID);
    if (listingClick.newWindow) {
      window.open(this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString());
    } else {
      this.router.navigate(routerCommands);
    }
  }

  goToVehicleHistoryRequest(classifiedAdID: string) {
    this.saveLastPosition(classifiedAdID);
    this.router.navigate(['/vehicleHistoryRequest', classifiedAdID]);
  }

  hideSignUpModal() {
    this.userSessionService.doNotShowSignUpModal = true;
    if (this.signUpModal) {
      this.signUpModal.nativeElement.classList.add("d-none");
    }
  }

  loadBetterListings(search: FindBetterListingsSearch) {
    this.indicateStartSearch();
    this.searchService.findBetterListings(search).subscribe((results: VehicleListing[]) => {
      this.listingsIntoResults(results);
      this.listingCount = results.length;
      this.resultCount = results.length;
      this.searchStatus = this.SEARCH_BEST_PRICE_COMPLETE;
    });
  }

  loadResults(searchRequest: SearchRequest | null) {
    if (searchRequest === null) {
      this.searchStatus = this.SEARCH_INIT;
    } else {
      this.searchRadius = +searchRequest.searchFormData.radius;
      this.currentSearch = {...searchRequest.searchFormDiffFromDefault};
      this.userSessionService.lastSearch = searchRequest.searchFormDiffFromDefault;
      this.userSessionService.zipCode = searchRequest.searchFormData.zip;
      this.router.navigate(['/search', searchRequest.searchFormDiffFromDefault]);

      // let the user know we're doing something
      this.indicateStartSearch();

      // reset the result count so the previous count isn't displayed while we count the new results
      this.resultCount = 0;

      // combine searchFormData and searchFormPaymentParams into a single object (which is what the server expects)
      const searchObject: ExtendedSearchFormData = {...searchRequest.searchFormData};
      const monthlyPaymentParameters: MonthlyPaymentParameters = {
        termMonths: searchRequest.searchFormPaymentParams.preferredTermMonths,
        rateServiceType: searchRequest.searchFormPaymentParams.rateService.kind,
        tradeInValue: searchRequest.searchFormPaymentParams.tradeInValue,
        otherDownPayment: searchRequest.searchFormPaymentParams.otherDownPayment,
        tradeInSalesTaxCredit: searchRequest.searchFormPaymentParams.tradeInSalesTaxCredit,
        salesTaxRate: searchRequest.searchFormPaymentParams.salesTaxRate,
        transactionFees: searchRequest.searchFormPaymentParams.transactionFees,
        balloonLoanFee: this.configService.balloonLoanFee,
        productsEnabled: [...searchRequest.searchFormPaymentParams.productsEnabled],
        productQuotes: [],
      };
      if (searchRequest.searchFormPaymentParams.rateService.kind === 'creditScoreTable') {
        const isNewSearch = searchRequest.searchFormData.condition === 'New';
        monthlyPaymentParameters.creditTierId = searchRequest.searchFormPaymentParams.rateService.getCurrentCreditTierId(isNewSearch);
      } else if (searchRequest.searchFormPaymentParams.rateService.kind === 'userInput') {
        monthlyPaymentParameters.interestRate = searchRequest.searchFormPaymentParams.rateService.interestRate;
      }
      searchObject['monthlyPaymentParameters'] = monthlyPaymentParameters;

      // find matching listings
      this.searchService.findListings(searchObject).subscribe((results: VehicleListing[]) => {
        this.listingsIntoResults(results);
        this.searchStatus = this.SEARCH_STANDARD_COMPLETE;
        this.listingCount = results.length;

        if (this.configService.virtualCarSaleConfig && !this.userSessionService.carSaleRegistered) {
          this.searchResults.splice(2, 0, new CarSaleRegistrationLeadResult());
        }
        else if (this.configService.sweepstakes && !this.userSessionService.sweepstakesEntered) {
          this.searchResults.splice(2, 0, new SoftLeadResult());
        }
      });

      // also count the total number of results so we can show the user and enable next/prev buttons if needed
      this.searchService.countListings(searchObject).subscribe((resultCount: number) => {
        this.resultCount = resultCount;
      });
    }
  }

  nextPage() {
    const nextPageNum = this.currentPage() + 1;
    this.currentSearch['pageNum'] = nextPageNum.toString();
    this.router.navigate(['/search', this.currentSearch]);
  }

  prevPage() {
    const prevPageNum = this.currentPage() - 1;
    this.currentSearch['pageNum'] = prevPageNum.toString();
    this.router.navigate(['/search', this.currentSearch]);
  }

  totalPages() {
    return Math.ceil(this.resultCount / this.configService.resultsPerPage);
  }

  userIsLoggingOut() {
    // Angular will not automatically realize that this view needs to change when the user logs out.
    // Trigger a check for changes so that (e.g.) the save vehicle buttons are updated.
    this.changeDetectorRef.markForCheck()
  }

  private indicateStartSearch() {
    this.searchStatus = this.SEARCH_ACTIVE;
    window.parent.postMessage({eventType: 'scrollToTop'}, '*');
  }

  private saveLastPosition(classifiedAdID: string) {
    const listingElement = document.getElementById('listing' + classifiedAdID);
    if (listingElement) {
      this.lastScrollPositionY = listingElement.getBoundingClientRect().top;
    }
  }

  private listingsIntoResults(listings: VehicleListing[], searchRadius: number = 0): void {
    this.searchResults = [];
    this.moreSearchResults = [];

    // Note: preserving the relative search order received from the server is important
    listings.forEach(listing => {
      // Note: a searchRadius of 0 actually means "unlimited radius"
      if (this.searchRadius > 0 && listing.location.distanceMiles > this.searchRadius) {
        this.moreSearchResults.push(new VehicleListingResult(listing));
      } else {
        this.searchResults.push(new VehicleListingResult(listing));
      }
    });
  }

}
