<div class=" d-flex flex-row justify-content-center">
  <form [formGroup]="resetPasswordForm" role="form" method="post" id="resetPasswordForm" (ngSubmit)="doSubmitNewPassword()" class="validated-form mt-4 p-4">
    <div class="form-group text-center m-0">
      <h1 class="text-center mb-3">Set New Password</h1>
      <div>
        <label for="createPassword">New Password</label>
        <input type="password" class="form-control mr-auto ml-auto mb-2" id="createPassword" formControlName="password" placeholder="Set a password..." #passwordResetInput>
        <label for="confirmPassword">Confirm Password</label>
        <input type="password" class="form-control mr-auto ml-auto mb-2" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm your password...">
        <button type="submit" class="btn btn-primary mt-2">Save password</button>
        <br>
        <button class="btn btn-secondary mt-2" [routerLink]="['/login']">Go to login page</button>&nbsp;<button class="btn btn-secondary mt-2" [routerLink]="['/search']">Go to search page</button>
        <p class="msg mt-4 mb-0 text-justify" *ngIf="msg">{{msg}}</p>
      </div>
    </div>
  </form>
</div>