import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  imports: [CommonModule],
  selector: 'app-new-listing-flag',
  standalone: true,
  templateUrl: './new-listing-flag.component.html',
  styleUrls: ['./new-listing-flag.component.sass']
})
export class NewListingFlagComponent implements OnInit {

  @Input() vcsEnabled: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
