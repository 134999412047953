import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from "../../../services/config.service";
import { SRPComponent } from '../srp.component';
import { SRPComponentL2 } from '../srp-l2.component'

@Component({
  imports: [CommonModule, SRPComponent, SRPComponentL2],
  selector: 'app-srp-wrapper',
  standalone: true,
  templateUrl: './srp-wrapper.component.html',
  styleUrls: ['./srp-wrapper.component.sass']
})
export class SRPWrapperComponent {
  useL2Version: boolean = false;

  constructor(private configService: ConfigService) {
  }

  ngOnInit() {
    this.configService.loadConfig();
  
    this.configService.defaultSearchRadius$.subscribe((radius) => {
      if (this.configService.virtualCarSaleConfig) {
        this.useL2Version = true;
      } else {
        this.useL2Version = false;
      }
    });
  }
}