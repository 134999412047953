<div class="container-lg py-2">
  <p *ngIf="listings.length === 0" class="text-center display-4 m-5">Loading&hellip;</p>
  <div class="table-responsive" *ngIf="listings.length > 0">
    <table class="table-striped table-sm" [width]="calcTableWidth() * (1 + listings.length)">
      <thead>
        <tr *ngIf="listings.length >= 6">
          <th [attr.colspan]="listings.length + 1" class="h4 mb-4 p-3 bg-primary text-light">Scroll the table to the right/left to see more.</th>
        </tr>
        <tr>
          <th [width]="calcTableWidth()">
            <button type="button" class="btn btn-primary" (click)="searchService.goToSRP()">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16"><path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/></svg>
              Search Page
            </button>
          </th>
          <th *ngFor="let listing of listings" [width]="calcTableWidth()">
            <div class="d-flex flex-column">
              <span class="listing-descr" title="{{listing.make}} {{listing.model}} {{listing.trim}}">{{listing.make}} {{listing.model}} {{listing.trim}}</span>
              <img [src]="listing.imageUrl" alt="" class="mt-1 vehicle-img" [routerLink]="['/listing', listing.classifiedAdID]">
              <img src="/assets/img/trash.png" alt="Remove this listing" class="trash" (click)="removeVehicle(listing)">
              <button class="btn btn-sm btn-dark mt-2" [routerLink]="['/listing', listing.classifiedAdID]">View Listing</button>
              <button class="btn btn-sm btn-secondary mt-1" [routerLink]="['/checkAvailability', listing.classifiedAdID]">Contact Seller</button>
            </div>
          </th>
        </tr>
        <tr class="fix-to-top" data-show-below="500">
          <th [width]="calcTableWidth()"></th>
          <th *ngFor="let listing of listings" [width]="calcTableWidth()">
            <div class="d-flex flex-column align-items-center">
              <span class="listing-descr text-center" title="{{listing.make}} {{listing.model}} {{listing.trim}}">{{listing.make}} {{listing.model}} {{listing.trim}}</span>
              <img [src]="listing.imageUrl" alt="" class="mt-1 vehicle-img vehicle-img-sm" [routerLink]="['/listing', listing.classifiedAdID]">
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Price</td>
          <td *ngFor="let listing of listings"><strong>{{listing.price | currency:'USD':digitsInfo:'1.0-0'}}</strong></td>
        </tr>
        <tr>
          <td><ng-container *ngIf="hasAtLeastOneAFG(listings)">Conventional</ng-container> Loan Payment <span class="small">(Illustrative)</span></td>
          <td *ngFor="let listing of listings"><app-monthly-payment [listing]="listing"></app-monthly-payment></td>
        </tr>
        <ng-container *ngIf="hasAtLeastOneAFG(listings)">
          <tr>
            <td>{{configService.balloonLoanProgramName}} Loan Payment <span class="small">(Illustrative)</span></td>
            <td *ngFor="let listing of listings"><app-monthly-payment [listing]="listing" [conventionalLoan]="false"></app-monthly-payment></td>
          </tr>
        </ng-container>
        <tr>
          <td>Condition</td>
          <td *ngFor="let listing of listings">{{listing.isNew ? 'New' : 'Used'}}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td *ngFor="let listing of listings">{{listing.location.city}}, {{listing.location.state}}</td>
        </tr>
        <tr>
          <td>Model Year</td>
          <td *ngFor="let listing of listings">{{listing.year}}</td>
        </tr>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'mileage', false)">
          <tr>
            <td>Odometer</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.mileage !== null">{{listing.mileage | number}}</ng-container>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'daysOnMarket', false)">
          <tr>
            <td>Days on Market&nbsp;&nbsp;<span ngbTooltip="How many days has this vehicle been listed for sale?"><img src="/assets/img/help.png" alt="More information" class="help-icon"></span></td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.daysOnMarket > 0">
                {{listing.daysOnMarket}} day<ng-container *ngIf="listing.daysOnMarket !== 1">s</ng-container>
              </ng-container>
              <ng-container *ngIf="listing.daysOnMarket === 0">
                Just listed today!
              </ng-container>
              <span *ngIf="listing.daysOnMarket < 0" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'averageDaysOnMarket', false)">
          <tr>
            <td>Average Time on Market&nbsp;&nbsp;<span ngbTooltip="How many days has the typical vehicle of the same year/make/model/trim been listed for sale (nationwide average)?"><img src="/assets/img/help.png" alt="More information" class="help-icon"></span></td>
            <td *ngFor="let listing of listings">
              <span *ngIf="listing.moreDetail?.averageDaysOnMarket != null">{{ listing.moreDetail?.averageDaysOnMarket }}</span>
              <span *ngIf="listing.moreDetail?.averageDaysOnMarket == null" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'transmission', true)">
        <tr>
          <td>Transmission</td>
          <td *ngFor="let listing of listings">
            <ng-container *ngIf="listing.moreDetail?.transmission">{{listing.moreDetail?.transmission}}</ng-container>
            <span *ngIf="!listing.moreDetail?.transmission" class="text-muted">n/a</span>
          </td>
        </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'cylinders', false)">
          <tr>
            <td>Cylinders</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.cylinders">{{listing.cylinders}}</ng-container>
              <span *ngIf="!listing.cylinders" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'vin', true)">
          <tr>
            <td>VIN</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.vin">{{listing.vin}}</ng-container>
              <span *ngIf="!listing.vin" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'bodyStyle', false)">
          <tr>
            <td>Bodystyle</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.bodyStyle">{{listing.bodyStyle}}</ng-container>
              <span *ngIf="!listing.bodyStyle" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'fuelType', true)">
          <tr>
            <td>Fuel type</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.moreDetail?.fuelType">{{listing.moreDetail?.fuelType}}</ng-container>
              <span *ngIf="!listing.moreDetail?.fuelType" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'cityMpg', true)">
          <tr>
            <td>Fuel consumption (city)</td>
            <td *ngFor="let listing of listings">
              <span *ngIf="listing.moreDetail?.cityMpg">{{listing.moreDetail?.cityMpg}} <span class="small">miles per gallon</span></span>
              <span *ngIf="!listing.moreDetail?.cityMpg" class="text-muted">unknown</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'hwyMpg', true)">
          <tr>
            <td>Fuel consumption (highway)</td>
            <td *ngFor="let listing of listings">
              <span *ngIf="listing.moreDetail?.hwyMpg">{{listing.moreDetail?.hwyMpg}} <span class="small">miles per gallon</span></span>
              <span *ngIf="!listing.moreDetail?.hwyMpg" class="text-muted">unknown</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'engine', true)">
          <tr>
            <td>Engine</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.moreDetail?.engine && listing.moreDetail?.engine?.trim() != ''">{{listing.moreDetail?.engine}}</ng-container>
              <span *ngIf="!listing.moreDetail?.engine || listing.moreDetail?.engine?.trim() == ''" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'drivetrain', false)">
          <tr>
            <td>Drivetrain</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.drivetrain">{{listing.drivetrain}}</ng-container>
              <span *ngIf="!listing.drivetrain" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'maxCargoCapacity', true)">
          <tr>
            <td>Max Cargo Capacity</td>
            <td *ngFor="let listing of listings">
              <span *ngIf="listing.moreDetail?.maxCargoCapacity">{{listing.moreDetail?.maxCargoCapacity}} ft.<sup>3</sup></span>
              <span *ngIf="!listing.moreDetail?.maxCargoCapacity" class="text-muted">unknown</span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'doorCount', true)">
          <tr>
            <td>Door count</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.moreDetail?.doorCount">{{listing.moreDetail?.doorCount}}</ng-container>
              <span *ngIf="!listing.moreDetail?.doorCount" class="text-muted">n/a</span>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>Certified pre-owned?</td>
          <td *ngFor="let listing of listings">{{listing.moreDetail?.isCpo ? 'Yes' : 'No'}}</td>
        </tr>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'exteriorColor', true)">
          <tr>
            <td>Exterior Color</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.moreDetail?.exteriorColor">{{listing.moreDetail?.exteriorColor}} <span *ngIf="listing.moreDetail?.exteriorColor !== listing.moreDetail?.exteriorColorNormalized && listing.moreDetail?.exteriorColorNormalized?.trim() != ''">({{listing.moreDetail?.exteriorColorNormalized}})</span></ng-container>
              <span *ngIf="!listing.moreDetail?.exteriorColor" class="text-muted">n/a</span></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="hasAtLeastOneInstance(listings, 'interiorColor', true)">
          <tr>
            <td>Interior Color</td>
            <td *ngFor="let listing of listings">
              <ng-container *ngIf="listing.moreDetail?.interiorColor">{{listing.moreDetail?.interiorColor}} <span *ngIf="listing.moreDetail?.interiorColor !== listing.moreDetail?.interiorColorNormalized && listing.moreDetail?.interiorColorNormalized?.trim() != ''">({{listing.moreDetail?.interiorColorNormalized}})</span></ng-container>
              <span *ngIf="!listing.moreDetail?.interiorColor" class="text-muted">n/a</span></td>
          </tr>
        </ng-container>
        <tr>
          <td [attr.colspan]="listings.length + 1"><h2 class="text-center my-5">More Features</h2></td>
        </tr>
        <tr *ngFor="let featureName of fullFeatureListNames">
          <td>{{featureName}}</td>
          <td *ngFor="let listing of listings">
            <div *ngIf="!fullFeatureList.has('#' + listing.id + '-' + featureName)" class="text-muted text-center">&mdash;</div>
            <div *ngIf="fullFeatureList.has('#' + listing.id + '-' + featureName)" class="text-center feature-text">
              <img *ngIf="fullFeatureList.get('#' + listing.id + '-' + featureName) === true" src="/assets/img/checkmark.svg" alt="Yes" class="checkmark">
              <span *ngIf="fullFeatureList.get('#' + listing.id + '-' + featureName) !== true">{{fullFeatureList.get('#' + listing.id + '-' + featureName)}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-footer></app-footer>
</div>