import { VehicleListing } from './vehicle-listing';

export interface SearchResult {
  resultType: string;
}

export class VehicleListingResult implements SearchResult {
  resultType: string = "VehicleListingResult";

  constructor(
    public listing: VehicleListing,
  ) { }
}

export class SoftLeadResult implements SearchResult {
  resultType: string = "SoftLeadResult";
};

export class CarSaleRegistrationLeadResult implements SearchResult {
  resultType: string = "CarSaleRegistrationLeadResult";
};