<div class="container-fluid">
  <div *ngIf="configService.showSignUpModal && !userSessionService.isLoggedIn" class="fix-to-top d-none" #signUpModal>
    <div class="card text-white bg-primary">
      <div class="card-header">
        <h5 class="card-title mb-0">Sign up &mdash; free for members</h5>
      </div>
      <div class="card-body text-dark bg-light">
        <div class="row">
          <div class="col-sm-4 text-center">
            <img src="/assets/img/sign-up.svg" alt="" class="pt-sm-3 pl-sm-2">
          </div>
          <div class="col-sm-8">
            <p class="card-text">With an account, you can easily <strong>save your favorite vehicles and searches</strong> so you can pick up where you left off when you come back later.</p>
            <p class="card-text">Signing up only takes a few seconds.</p>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="card-text"><button type="button" class="btn btn-success mr-2" [routerLink]="['/createAccount']">Sign Up</button><button type="button" class="btn btn-link mr-2" [routerLink]="['/login']">Login</button><button type="button" class="btn btn-warning float-right" (click)="hideSignUpModal()">Not Now</button></p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 pl-md-0">
      <app-search-form (newSearch)="loadResults($event)"></app-search-form>
    </div>

    <div class="col">
      <ng-container [ngSwitch]="searchStatus">
        <div *ngSwitchCase="SEARCH_INIT">
          <div class="card">
            <div class="card-body">
              <div class="d-sm-flex justify-content-between align-items-center">
                <p class="lead mb-sm-0"><span *ngIf="userSessionService.firstName">Hello, {{userSessionService.firstName}}. </span>Please use the search form to begin your search for your next vehicle.</p>
                <app-account-btns (loggedOut)="userIsLoggingOut()"></app-account-btns>
              </div>
            </div>
          </div>

          <div class="mt-3"><app-favorite-vehicles-list></app-favorite-vehicles-list></div>

          <p *ngIf="(configService.loanAppUrl$ | async)?.length" class="alert alert-light mt-3">Are you pre-approved for your loan? If not, <a target="_blank" [href]="configService.loanAppUrl$ | async" (click)="userSessionService.recordLoanAppAccess()">get pre-approved now</a> and find out how much you're qualified to borrow. <ng-container *ngIf="configService.lowestRate >= 0">Rates start as low as {{configService.lowestRate}}%.</ng-container></p>
        </div>

        <div *ngSwitchCase="SEARCH_ACTIVE">
          <div class="card">
            <div class="card-body">
              <p class="lead mb-0">Please wait while we find vehicles matching your search.</p>
            </div>
          </div>
          <p class="m-5 text-center"><img src="/assets/img/searching.gif" alt="Searching..."></p>
        </div>

        <ng-container *ngSwitchDefault>
          <div *ngIf="listingCount > 0" class="main-content">
            <div class="card">
              <div class="card-body">
                <div class="d-sm-flex justify-content-between align-items-center">
                  <p class="lead mb-2 m-sm-0">
                    Hi<span *ngIf="userSessionService.firstName">, {{userSessionService.firstName}}</span>!

                    Here <span *ngIf="listingCount > 1">are <span *ngIf="listingCount < configService.resultsPerPage">the </span> <strong>{{listingCount}}</strong></span><span *ngIf="listingCount === 1">is the only</span> vehicle<span *ngIf="listingCount > 1">s</span> matching your <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE"><b>Find Better Price </b></span>search.

                    <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE && listingCount > 1">The vehicle you are comparing against is shown first.</span>

                    <span *ngIf="searchStatus === SEARCH_BEST_PRICE_COMPLETE && listingCount === 1">Your choice was already the best priced vehicle within the search region!</span>

                    <span class="text-muted" *ngIf="resultCount > configService.resultsPerPage">There are {{resultCount | number}} total matching results. You are on page {{currentPage()}} of {{totalPages()}}.</span>

                    <span class="text-muted" *ngIf="resultCount === null">Loading total number of matching vehicles...</span>
                  </p>
                  <app-account-btns (loggedOut)="userIsLoggingOut()"></app-account-btns>
                </div>
              </div>
            </div>

            <!-- Virtual Car Sale banner -->
            <div *ngIf="configService.virtualCarSaleConfig?.id">
              <div class="card mt-2 virtual-car-sale">
                <div class="card-body py-2 px-2">
                  <div class="d-flex flex-row align-items-center">
                    <img src="/assets/img/virtual-car-sale.png" alt="" class="mx-2">
                    <!-- Without "mb-0" this isn't centered for some reason -->
                    <h6 class="mb-0">
                      {{ configService.virtualCarSaleConfig?.description }}&nbsp;
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style="white-space: nowrap;"
                        *ngIf="configService.virtualCarSaleConfig?.learnMoreURL"
                        [href]="configService.virtualCarSaleConfig?.learnMoreURL"
                      >
                        Learn More
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3"><app-favorite-vehicles-list></app-favorite-vehicles-list></div>

            <ng-container *ngTemplateOutlet="searchResultRow; context: {$implicit: searchResults}"></ng-container>

            <ng-container *ngIf="moreSearchResults.length > 0">
              <p class="alert alert-warning mt-4 mb-2 lead">The following <span *ngIf="moreSearchResults.length > 1">listings are</span><span *ngIf="moreSearchResults.length === 1">listing is</span> outside your search radius of {{searchRadius}} mile<span *ngIf="searchRadius > 1">s</span>.</p>
              <ng-container *ngTemplateOutlet="searchResultRow; context: {$implicit: moreSearchResults}"></ng-container>
            </ng-container>

            <button *ngIf="+currentSearch['pageNum'] > 1" class="btn btn-lg btn-dark mt-3 mb-4 mr-3" (click)="prevPage()">⇦ Previous Page</button>
            <button *ngIf="resultCount > configService.resultsPerPage" class="btn btn-lg btn-dark mt-3 mb-4" (click)="nextPage()">Next Page ⇨</button>
          </div>

          <div *ngIf="listingCount === 0">
            <p class="alert alert-warning mt-4 lead">There are no results that match your search.</p>
          </div>
        </ng-container>
      </ng-container>

      <app-footer></app-footer>
    </div>
  </div>
</div>

<ng-template #searchResultRow let-results>
  <div class="search-results row">
    <ng-container *ngFor="let result of results">
      <app-search-result *ngIf="result.resultType === 'VehicleListingResult'" [listing]="result.listing" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxl-3" [id]="'listing' + result.listing.classifiedAdID" (findBetter)="loadBetterListings($event)" (checkAvailabilityView)="goToCheckAvailability($event)" (requestVehicleHistoryView)="goToVehicleHistoryRequest($event)" (compareView)="goToCompare($event)" (listingView)="goToListing($event)" (loansView)="goToLoans($event)"></app-search-result>
      <app-soft-lead *ngIf="result.resultType == 'SoftLeadResult'" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxl-3"></app-soft-lead>
      <ng-container *ngIf="configService.virtualCarSaleConfig?.registration">
        <app-car-sale-registration *ngIf="result.resultType == 'CarSaleRegistrationLeadResult'" class="search-result col-md-12 col-lg-6 col-xl-4 col-xxl-3"></app-car-sale-registration>
      </ng-container>
    </ng-container>
  </div>
</ng-template>