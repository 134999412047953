import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import { ViewChild } from '@angular/core';

import { AFGService } from "@services/afg.service";
import { CarFaxService } from  '@services/car-fax.service';
import { ConfigService } from '@services/config.service';
import { ListingClick } from '../../models/listing-click';
import { FindBetterListingsSearch } from '@services/search.service';
import { UserSessionService } from '@services/user-session.service';
import { VehicleListing } from '@models/vehicle-listing';
import { VTDButtonService } from '@services/vtd-button.service';
import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';
import { NewListingFlagComponent } from '../new-listing-flag/new-listing-flag.component';
import { ClipboardService } from '@services/clipboard.service';
import { environment } from '@environments/environment';

import { Router } from '@angular/router';



@Component({
  imports: [CommonModule, NewListingFlagComponent, RouterModule, VehicleSearchSharedModule, MatMenuModule ],
  selector: 'app-search-result',
  standalone: true,
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.sass']
})
export class SearchResultComponent implements OnInit {

  digitsInfo: any; // needed for TypeScript to accept digitsInfo parameter in currency pipe

  @Input() listing!: VehicleListing;
  @Output() checkAvailabilityView: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestVehicleHistoryView: EventEmitter<string> = new EventEmitter<string>();
  @Output() compareView: EventEmitter<string> = new EventEmitter<string>();
  @Output() findBetter: EventEmitter<FindBetterListingsSearch> = new EventEmitter<FindBetterListingsSearch>();
  @Output() listingView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();
  @Output() loansView: EventEmitter<ListingClick> = new EventEmitter<ListingClick>();

  // Changes to this value need to be coordinated with CarBuyingServiceImpl.FIND_BETTER_PRICE_REGION
  readonly FIND_BETTER_PRICE_REGION = 500;

  constructor(
    public afg: AFGService,
    public carFaxService: CarFaxService,
    public configService: ConfigService,
    public userSessionService: UserSessionService,
    private vtd: VTDButtonService,
    private clipboardService: ClipboardService,
    private router: Router,

  ) { }

  ngOnInit() {
  }

  checkAvailability() {
    this.checkAvailabilityView.emit(this.listing.classifiedAdID);
  }

  doFindBetter(locationRestriction: string) {
    this.findBetter.emit({
      selectedVehicle: this.listing,
      locationRestriction: locationRestriction,
    });
  }

  requestVehicleHistory() {
    this.requestVehicleHistoryView.emit(this.listing.classifiedAdID);
  }

  viewListing(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.listingView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  viewListingToNewWindowFromMenu(event : MouseEvent) {
    const isNewWindow = (event.button == 0) ? true : false;
    this.listingView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: isNewWindow});
  }

  viewLoans(event: MouseEvent) {
    const openInNewWindow = event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
    this.loansView.emit({classifiedAdID: this.listing.classifiedAdID, newWindow: openInNewWindow});
  }

  getHasAFG() {
    if (this.configService.balloonLoanProgramName != null) {
      return true;
    } else {
      return false;
    }
  }

  // details
  playVtd() {
    if (this.listing.moreDetail && this.listing.moreDetail.vtdUrl) {
      this.vtd.play(this.listing.moreDetail.vtdUrl, this.listing.classifiedAdID);
    }
  }

  showAfgBadge(): Observable<boolean> {
    return this.afg.isAfgEligible(this.listing);
  }

  
  @ViewChild('rightMenuTrigger') rightMenuTrigger!: MatMenuTrigger;
  menuTopLeftPosition = { x: '0', y: '0' };

  onRightClick(event: MouseEvent) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    // we open the menu
    this.rightMenuTrigger.openMenu();
  }


  copyLinkToListing($event: MouseEvent) {
    const routerCommands = ['/listing', this.listing.classifiedAdID];
    const url = this.configService.cu.autoLinkPageUrl + environment.iframeHashKey + this.router.createUrlTree(routerCommands).toString();
    const successMsg = 'Link to <strong>' + this.listing.make + ' ' + this.listing.model + '</strong> copied';
    this.clipboardService.copy(url, successMsg, $event);
  }

}
