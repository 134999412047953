import { Component, ElementRef, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfigService } from '@services/config.service';
import { LeadService } from '@services/lead.service';
import { PhoneFormatter } from '@cbs-common/phone-formatter';
import { UserSessionService } from '@services/user-session.service';

import { environment } from '@environments/environment';
import phoneNumberValidator from '@cbs-common/phone-number-validator';

@Component({
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'app-soft-lead',
  standalone: true,
  templateUrl: './soft-lead.component.html',
  styleUrls: ['./soft-lead.component.sass']
})
export class SoftLeadComponent implements OnInit {

  @ViewChild('firstNameInput', {static: false}) firstNameInput!: ElementRef;

  contactForm!: FormGroup;
  status!: number;

  readonly INIT = 0;
  readonly FORM = 1;
  readonly DONE = 2;

  constructor(
    public configService: ConfigService,
    private formBuilder: FormBuilder,
    private leadService: LeadService,
    private userSessionService: UserSessionService,
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      question: ['How much of a loan do I qualify for?'],
      customQuestion: [''],
      firstName: [this.userSessionService.firstName, [Validators.required]],
      lastName: [this.userSessionService.lastName, [Validators.required]],
      emailAddress: [this.userSessionService.emailAddress, [Validators.required, Validators.email]],
      phoneNumber: [this.userSessionService.phoneNumber, phoneNumberValidator],
    });

    this.status = this.INIT;
  }

  formatPhoneInContactForm() {
    const phoneNumber = this.contactForm.get('phoneNumber');
    if (phoneNumber) {
      const val = phoneNumber.value;
      const formatted = PhoneFormatter.format(val);
      if (formatted !== '' && formatted !== val) {
        phoneNumber.setValue(formatted);
      }
    }
  }

  selectQuestion() {
    const question = this.contactForm.get('question');
    if (question) {
      this.status = this.FORM;
      this.userSessionService.recordSoftLeadClick(question.value);
      setTimeout(() => this.firstNameInput.nativeElement.focus(), 0);
    }
  }

  submitContactInformation() {
    if (this.contactForm.status === 'VALID') {
      this.leadService.submitSoftLead(this.configService.sweepstakes.id, this.contactForm.value).subscribe({
        next: () => {
          this.userSessionService.sweepstakesEntered = true;
          this.userSessionService.currentUser = this.contactForm.value;
          this.status = this.DONE;
        },
        error: () => {
          window.parent.postMessage({eventType: 'alert', alertMessage: 'Unable to communicate with the server. Please check your Internet connection and try again.'}, environment.apiBaseUrl);
        }
      });
    }
  }

}
