import { Component } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Event, NavigationStart, Router, RouterOutlet } from '@angular/router';

import { VehicleSearchSharedModule } from '@cbs-common/vehicle-search-shared.module';

import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [VehicleSearchSharedModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  
  constructor(
    private router: Router,
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        window.parent.postMessage({eventType: 'setAngularHash', pagePath: event.url}, environment.apiBaseUrl);
      }
    });
  }

}
