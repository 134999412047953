import { Component, ElementRef, OnInit, Input, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

import { ConfigService } from "@services/config.service";
import { LeadService } from "@services/lead.service";
import { PhoneFormatter } from "@cbs-common/phone-formatter";
import { UserSessionService } from "@services/user-session.service";

import { environment } from "@environments/environment";
import phoneNumberValidator from "@cbs-common/phone-number-validator";

@Component({
  imports: [CommonModule, ReactiveFormsModule],
  selector: "app-car-sale-registration",
  standalone: true,
  templateUrl: "./car-sale-registration.component.html",
  styleUrls: ["./car-sale-registration.component.sass"],
})
export class CarSaleRegistrationComponent implements OnInit {

  contactForm!: FormGroup;
  status!: number;

  readonly INIT = 0;
  readonly DONE = 1;

  constructor(
    public configService: ConfigService,
    private formBuilder: FormBuilder,
    private leadService: LeadService,
    private userSessionService: UserSessionService
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: [this.userSessionService.firstName, [Validators.required]],
      lastName: [this.userSessionService.lastName, [Validators.required]],
      emailAddress: [this.userSessionService.emailAddress, [Validators.required, Validators.email]],
      phoneNumber: [
        this.userSessionService.phoneNumber,
        [Validators.required, phoneNumberValidator],
      ],
    });

    this.status = this.INIT;
  }

  formatPhoneInContactForm() {
    const phoneNumber = this.contactForm.get("phoneNumber");
    if (phoneNumber) {
      const val = phoneNumber.value;
      const formatted = PhoneFormatter.format(val);
      if (formatted !== "" && formatted !== val) {
        phoneNumber.setValue(formatted);
      }
    }
  }

  submitContactInformation() {
    if (this.contactForm.status === "VALID" && this.configService.virtualCarSaleConfig) {
      this.leadService
        .submitCarSaleRegistrationLead(
          this.configService.virtualCarSaleConfig.id,
          this.contactForm.value
        )
        .subscribe(
          () => {
            this.userSessionService.carSaleRegistered = true;
            this.userSessionService.currentUser = this.contactForm.value;
            this.status = this.DONE;
          },
          (error: HttpErrorResponse) => {
            const message =
              error.status === 400
                ? "Form invalid. Make sure your information was entered correctly."
                : "Something went wrong, please try again later.";
            window.parent.postMessage(
              {
                eventType: "alert",
                alertMessage: message,
              },
              environment.apiBaseUrl
            );
          }
        );
    } else {
      window.parent.postMessage(
        { eventType: "alert", alertMessage: "No car sale appears to be running at this time." },
        environment.apiBaseUrl
      );
    }
  }
}
