<!-- Flip container -->
<div class="flipper" [ngClass]="{'flip': status !== SHOW_LOGIN, 'flipper-show-create': status === SHOW_CREATE}">

  <!-- Front side -->
  <div class="front mt-5 p-1 d-flex flex-row justify-content-center" id="flexBoxFront">
    <!-- Login -->
    <div id="login">
      <form [formGroup]="loginForm" role="form" method="post" id="loginForm" (ngSubmit)="doSubmitLogin()" class="validated-form">
        <div class="form-group">
          <div class="alert alert-warning" role="alert" *ngIf="introMsg">
            {{introMsg}}
          </div>
          <div class="alert alert-secondary d-flex justify-content-between align-items-center">
            <div>Don't have an account yet? Sign up now &mdash; it's fast and free.</div>
            <button type="button" class="btn btn-primary btn-sm" (click)="viewCreateAccount()">Sign Up</button>
          </div>
          <h1 class="text-center mb-3">Login</h1>
          <div class="container text-center">
            <div class="grid">
              <label for="emailAddress">Email address</label>
              <input type="email" class="form-control" id="emailAddress" formControlName="emailAddress" placeholder="Enter your email..." #emailLoginInput>
              <label for="password">Password</label>
              <input type="password" class="form-control" id="password" formControlName="password" placeholder="Enter your password...">
            </div>
            <button type="submit" class="btn btn-primary my-3">Log In</button>
            <p class="err-msg" *ngIf="loginMsg">{{loginMsg}}</p>
            <p class="flip-link" (click)="viewResetPassword()">Forgot your password?</p>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Back side -->
  <div class="back mt-5 p-1 d-flex flex-row justify-content-center" id="flexBoxBack">
    <!-- Create account -->
    <div id="createAccount" *ngIf="status === SHOW_CREATE">
      <form [formGroup]="createAccountForm" role="form" method="post" id="createAccountForm" (ngSubmit)="doSubmitNewAccount()" class="validated-form">
        <div class="form-group text-center">
          <h1 class="text-center mb-3">Create Account</h1>
          <div>
            <div class="row">
              <div class="col-sm-6">
                <label for="createFirstName">First name</label>
                <input type="text" class="form-control mr-auto ml-auto mb-2" id="createFirstName" formControlName="firstName" placeholder="Your first name..." #firstNameSignupInput>
              </div>
              <div class="col-sm-6">
                <label for="createLastName">Last name</label>
                <input type="text" class="form-control mr-auto ml-auto mb-2" id="createLastName" formControlName="lastName" placeholder="Your last name..." #lastNameSignupInput>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label for="createEmail">Email address</label>
                <input type="email" class="form-control mr-auto ml-auto mb-2" id="createEmail" formControlName="emailAddress" placeholder="Your email..." #emailSignupInput>
              </div>
              <div class="col-sm-6">
                <label for="createPhoneNumber">Phone Number</label><span id="tinyText">&nbsp;&nbsp;(optional)</span>
                <input type="tel" class="form-control mr-auto ml-auto mb-2" id="createPhoneNumber" formControlName="phoneNumber" placeholder="Your phone number..." #phoneSignupInput>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label for="createPassword">Password</label>
                <input type="password" class="form-control mr-auto ml-auto mb-2" id="createPassword" formControlName="password" placeholder="Create a password...">
              </div>
              <div class="col-sm-6">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" class="form-control mr-auto ml-auto mb-2" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm your password...">
              </div>
            </div>
            <div class="m-2 small text-muted text-justify">
              By submitting this form, you authorize the credit union and/or our other partners to contact you by texts/emails/calls which may include marketing and be by autodialer. Consent is not required to purchase goods/services.
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-2">Create my account</button>
            <p class="err-msg mt-4" *ngIf="createAccountMsg">{{createAccountMsg}}</p>
          </div>
          <div>
            <p class="flip-link mt-3" (click)="viewLogin()">Already have an account? Login here.</p>
          </div>
        </div>
      </form>
    </div>
    <!-- Account creation successful -->
    <div id="createAccountSuccessful" *ngIf="status === SHOW_CREATE2" class="p-5">
      <h1 class="text-center mb-3">Account Created</h1>
      <p>Hello, {{userSessionService.emailAddress}}, your account has been created, and you can now store Favorite Vehicles for later review.</p>
      <button type="button" class="btn btn-secondary mt-2" (click)="searchService.goToSRP()">Back to search</button>
    </div>
    <!-- Reset password -->
    <div id="resetPassword" *ngIf="status === SHOW_RESET">
      <form [formGroup]="resetPasswordForm" role="form" method="post" id="resetForm" (ngSubmit)="doSubmitResetPassword()" class="validated-form">
        <div class="form-group text-center">
          <h1 class="text-center mb-3">Reset Password</h1>
          <div class="container">
            <label for="emailResetPassword">Email address</label>
            <input type="email" class="form-control mr-auto ml-auto mb-2" id="emailAddress" formControlName="emailAddress" placeholder="Enter your email..." #emailResetInput>
            <button type="submit" class="btn btn-primary mt-2">Send me an account recovery email</button>
            <p class="err-msg mt-4" *ngIf="resetPasswordMsg">{{resetPasswordMsg}}</p>
          </div>
          <div>
            <button type="button" class="btn btn-secondary mt-2" (click)="viewLogin()">Go back</button>
          </div>
        </div>
      </form>
    </div>
    <!-- Reset password successful -->
    <div id="resetPasswordSuccessful" *ngIf="status === SHOW_RESET2" class="p-5">
      <h1 class="text-center mb-3">Next Step</h1>
      <p>Check your email account for a link to reset your password.</p>
      <button type="button" class="btn btn-secondary mt-2" (click)="searchService.goToSRP()">Back to search</button>
      <p class="flip-link mt-4" (click)="viewCreateAccount()">Or create a new account.</p>
    </div>
  </div>

</div>